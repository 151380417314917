import { useCallback, useState } from "react";
import { Flex, Modal } from "antd";
import moment from "moment";
import { Calendar, SlotInfo, Views, momentLocalizer } from "react-big-calendar";
import { useNavigate } from "react-router-dom";
import "moment-timezone";
import { useGetCalendarsListQuery } from "../../store/api/calendar.api";

moment.tz.setDefault("Europe/Kiev");
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const myEventsList = [
  {
    title: "Test 1",
    start: new Date(2024, 5, 12),
    end: new Date(2024, 5, 12),
  },
  {
    title: "Test 1",
    start: new Date(2024, 5, 13),
    end: new Date(2024, 5, 13),
  },
  {
    title: "Test 1",
    start: new Date(2024, 5, 14),
    end: new Date(2024, 5, 15),
  },
];

interface SelectedEvent {
  start: Date;
  end: Date;
}

interface IEventSelected {
  title: string;
  start: Date;
  end: Date;
}

const EventsCalendar = () => {
  console.log("Events Calendar");
  const navigate = useNavigate();

  const result = useGetCalendarsListQuery({ vendorId: 67 });
  console.log({ result });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [myEvents] = useState(myEventsList);
  const [newEvent, setNewEvent] = useState<SelectedEvent | null>(null);

  const handleSelectSlot = useCallback(({ start, end }: SlotInfo) => {
    setIsModalVisible(true);
    setNewEvent({ start, end });
  }, []);

  const handleEventSelected = (event: IEventSelected) => {
    console.log("handle event selected");
    console.log(event);
  };

  const handleCreateEvent = () => {
    navigate("/createEvent", {
      state: { start: newEvent?.start, end: newEvent?.end },
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setNewEvent(null);
    setIsModalVisible(false);
  };

  return (
    <Flex>
      <Calendar
        views={["month", "week", "work_week", "day", "agenda"]}
        defaultView={Views.WEEK}
        localizer={localizer}
        events={myEvents}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={(event) => handleEventSelected(event)}
        onSelectSlot={handleSelectSlot}
        style={{ height: 600 }}
        selectable
        timeslots={6}
        step={10}
      />
      <Modal
        title="Confirm Event Creation"
        open={isModalVisible}
        onOk={handleCreateEvent}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to create an event at this time?</p>
      </Modal>
    </Flex>
  );
};

export default EventsCalendar;

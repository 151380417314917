import { Form, Select } from "antd";
import { FC } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { CalendarEventForm } from "../../../../models/CalendarEventForm";

interface SelectStudentsProps {
  errors: FieldErrors;
  control: Control<CalendarEventForm>;
}

const options = [{ value: "student1", label: <span>student1</span> }];

export const SelectStudents: FC<SelectStudentsProps> = ({
  control,
  errors,
}) => {
  return (
    <Form.Item
      label="Виберіть студентів"
      help={<p>{`${errors.employeesIds && errors.employeesIds.message}`}</p>}
      validateStatus={errors.studentsIds ? "error" : ""}
    >
      <Controller
        name="studentsIds"
        control={control}
        render={({ field }) => (
          <Select mode="multiple" placeholder="Виберіть студентів" {...field}>
            {options.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )}
      />
    </Form.Item>
  );
};

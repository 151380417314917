import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { getVendorMoneyTransactionsFetch, setPageTransactions } from "../../../../store/state/finance/finance.slice";
import culcPageTable from "../../../../common/helpers/culcPageTable";
import usePagination from "../../../../common/hooks/usePagination";
import { Filter } from "../../../../common/models/Filters";
import { FinanceColumn } from "../../models/FinanceColumn";
import DataTableBlock from "./DataTableBlock";

interface Props {
    data: FinanceColumn[];
    vendorId: number;
}

const DataTableBlockPagination: FC<Props> = ({ data, vendorId }) => {
    const dispatch = useAppDispatch();

    const page = useAppSelector(state => state.finance.finance.page, shallowEqual);
    const totalCount = useAppSelector(state => state.finance.finance.totalCount, shallowEqual);
    const pageAll = useAppSelector(state => state.finance.finance.pageAll, shallowEqual);
    const pageSize = useAppSelector(state => state.finance.finance.pageSize, shallowEqual);
    const getAllItems = useAppSelector(state => state.finance.finance.getAllItems, shallowEqual);
    const type = useAppSelector(state => state.finance.finance.filter.type, shallowEqual);

    const setPage = (filter: Filter | undefined, pageNumber: number) => {
        dispatch(getVendorMoneyTransactionsFetch({ vendorId, filter }));
        dispatch(setPageTransactions({ page: pageNumber }));
    };

    const pagination = usePagination({
        getAllItems,
        pageAll,
        page,
        totalCount,
        pageSize,
        setPage,
        type,
    });

    const pagesRange = pagination.pagesRange;

    const pageTable = culcPageTable<FinanceColumn>(data, pagesRange.from, pagesRange.to);

    return (
        <DataTableBlock
            data={pageTable}
            page={page}
            pages={pagination.pages}
            handleLeftButtonPagination={pagination.handleLeftButtonPagination}
            handleRightButtonPagination={pagination.handleRightButtonPagination}
        />
    )
}

export default DataTableBlockPagination;
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { setMessageVisiable } from "../../store/state/vendors/vendors.slice";
import Message from "../../components/feedback/messages/Message/Message";
import { useMessageActions } from "../../common/hooks/useMessageActions";
import VendorsPagination from "./Vendors.pagination";
import { VendorsColumn } from "./models/VendorsColumn";

interface Props {
    data: VendorsColumn[];
    modals: JSX.Element[];
}

const VendorsMessage: FC<Props> = ({ data, modals }) => {
    const dispatch = useAppDispatch();

    const message = useAppSelector(state => state.vendors.message, shallowEqual);
    const visiable = useAppSelector(state => state.vendors.messageVisiable, shallowEqual);

    const setMessage = () => {
        dispatch(setMessageVisiable({visiable: false}));
    }
    
    const visiableActions = useMessageActions(visiable, setMessage);

    return (
        <>
            <Message 
                content={message || ""} 
                visiable={visiableActions} 
                position="top" 
            />
            
            <VendorsPagination 
                data={data}
                modals={modals}
            />
        </>
    );
}

export default React.memo(VendorsMessage);
import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { getGroupsListForVendorFetch } from "../../store/state/groups/groups.slice";
import { SYSTEM_ADMIN_ID } from "../../common/constants/role";
import { culcPage } from "../../common/helpers/culcPage";
import { Filter } from "../../common/models/Filters";

function withLoadingGroups(Component: React.ComponentType) {
    const ComponentWithLoadingGroups: FC = () => {
        const dispatch = useAppDispatch();

        const page = useAppSelector(state => state.groups.page, shallowEqual);
        const totalCount = useAppSelector(state => state.groups.totalCount, shallowEqual);
        const pageAll = useAppSelector(state => state.groups.pageAll, shallowEqual);
        const pageSize = useAppSelector(state => state.groups.pageSize, shallowEqual);
        const getAllItems = useAppSelector(state => state.groups.getAllItems, shallowEqual);
        const pagesRange = culcPage(page, pageAll, totalCount, pageSize);

        const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
        const roleId = useAppSelector(state => state.user.roleId, shallowEqual) || -1;
        const active = useAppSelector(state => state.groups.filter.active, shallowEqual);

        useEffect(() => {
            if (roleId > SYSTEM_ADMIN_ID) {
                let filter: Filter = { active };

                if (!getAllItems) {
                    filter.range = {
                        from: pagesRange.from,
                        to: pagesRange.to
                    }
                }

                dispatch(getGroupsListForVendorFetch({ vendorId, filter }));
            }
        }, [vendorId, roleId]);

        return (
            <Component />
        );
    }

    return ComponentWithLoadingGroups;
}

export default withLoadingGroups;
import { FormikProps } from "formik";
import React, { FC, useState } from "react";
import { shallowEqual } from "react-redux";
import NameView from "../../../../../../components/dataDisplay/table/views/NameView/NameView";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import RadioBlock from "../../../../../../components/dataEntry/inputs/RadioBlock/RadioBlock";
import Button from "../../../../../../components/general/buttons/Button/Button";
import IconFont from "../../../../../../components/general/icons/IconFont/IconFont";
import Row from "../../../../../../components/layout/Row/Row";
import Wrp from "../../../../../../components/layout/Wrp/Wrp";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { Client } from "../../../../../../store/state/clients/models/Client";
import { StudentGroup } from "../../../../../../store/state/groups/models/Group";
import { configListAdapter, ConfigValue } from "../../../../../../common/adapters/ConfigListAdapter";
import { discountsAdapter } from "../../../../../../common/adapters/DiscountsAdapter";
import { NEGATIVE_MEANING } from "../../../../../../common/constants/operationsWithNumbers";
import { getRelationship } from "../../../../../../common/helpers/getRelationship";
import { AddClientToGroupFormValues } from "../../../../models/AddClientToGroupFormValues";

interface Props {
    formik: FormikProps<AddClientToGroupFormValues>;
    closeModalHandler: () => void;
    searchListUsers: Client[];
    groupId: number;
    coursePrice: number;
    amountLessons: number;
}

const AddClientToGroupForm: FC<Props> = ({ closeModalHandler, formik, searchListUsers, groupId, coursePrice, amountLessons }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = formik;

    const studentsStatuses = useAppSelector(state => state.config.vendorConfig?.client.studentStatuses, shallowEqual);
    const studentsStatusesList = configListAdapter(studentsStatuses);
    
    const indexStatus = studentsStatusesList.findIndex(status => status.key === "notConfirmed");
    const trialLesson = studentsStatusesList[indexStatus]?.id;

    const relationshipStatuses = useAppSelector(state => state.config.userConfig?.user.relationshipStatuses, shallowEqual);

    const discounts = useAppSelector(state => state.money.discounts, shallowEqual);

    const submitButtonHandler = () => {
        handleSubmit();
    }

    const clickCancelButtonHandler = () => {
        closeModalHandler();
    }

    const studentsItems: JSX.Element[] = [];
    searchListUsers.forEach(client => {
        client.students
            .filter(student => student.groups.findIndex(group => group.id === groupId) === -1)
            .forEach(student => {
                const status = values.status;
                const discountId = client.discountId || -1;
                const discountItem = discountsAdapter(discounts, discountId);
                const discount = discountItem?.value || 0;

                let price = coursePrice;
                if(status === trialLesson) {
                    price = Math.floor(coursePrice / amountLessons);
                }
                
                // let discountPrice = price * (discount / 100);
                // let debt = (price - discountPrice) * NEGATIVE_MEANING;
                const debt = 0;

                const studentData: StudentGroup = {
                    student: {
                        id: student.id,
                        name: student.name,
                        surname: student.surname,
                    },
                    client: {
                        id: client.clientId,
                        users: client.users.map(user => ({
                            id: user.id,
                            name: user.name,
                            surname: user.surname,
                            email: user.email,
                            phone: user.phone,
                            relationshipStatus: user.relationshipStatus
                        }))
                    },
                    lessons: [],
                    debt,
                    discount,
                    status: trialLesson,
                    attendance: 0,
                }

                const relationship = getRelationship(relationshipStatuses, client.users[0].relationshipStatus);

                studentsItems.push(
                    <RadioBlock
                        name="student"
                        leftCol={<NameView
                            name={`${student.surname} ${student.name}`}
                            subname={`${relationship}: ${client.users[0].surname} ${client.users[0].name}`} />
                        }
                        rightCol={
                            <DropdownStatuses
                                setFieldValue={setFieldValue}
                                trialLesson={trialLesson}
                                studentsStatusesList={studentsStatusesList}
                            />
                        }
                        value={{
                            studentId: student.id,
                            status: trialLesson,
                        }}
                        handleChange={(value: AddClientToGroupFormValues) => {
                            setFieldValue("studentData", studentData);
                            setFieldValue("studentId", value.studentId);
                        }}
                    />
                )
            });
    });

    return (
        <Wrp flexDirection="column" alignItems="center">
            <>{studentsItems}</>
            <Row maxWidth={340} marginTop={10}>
                <Button
                    disabled={!isValid}
                    maxWidth={160}
                    handleClick={submitButtonHandler}
                    icon={<IconFont iconClass="icon-plus" fontSize={15} color="#FFF" />}
                    backgroundColor="#4775FE"
                    title="Додати"
                    marginBottom={0}
                    marginRight={15}
                />

                <Button
                    maxWidth={160}
                    handleClick={clickCancelButtonHandler}
                    backgroundColor="#A6AEC5"
                    title="Скасувати"
                    marginBottom={0}
                    marginRight={0}
                />
            </Row>
        </Wrp>
    );
}

interface PropsStatusesDropdown {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    trialLesson: number;
    studentsStatusesList: ConfigValue[];
}

const DropdownStatuses: FC<PropsStatusesDropdown> = ({ setFieldValue, trialLesson, studentsStatusesList }) => {
    const [state, setState] = useState(trialLesson);

    const statusIndex = studentsStatusesList.findIndex(status => status.key === "active");
    const activeLesson = studentsStatusesList[statusIndex]?.id;

    const statuses = [{ id: activeLesson, name: "Основний потік" }, { id: trialLesson, name: "Пробне заняття" }];

    const statusItems = statuses.map((status, index) => (
        <DropdownItem
            key={"STUDENTS_STATUS_ITEM_" + index}
            item={status.name}
            valueInput={String(status.id)}
            valueText={status.name}
        />
    ));

    const indexStatuses = statuses.findIndex(status => status.id === state);

    const defaultValue: DefaultValue = {
        valueInput: statuses[indexStatuses] && String(statuses[indexStatuses].id),
        valueText: statuses[indexStatuses] && statuses[indexStatuses].name
    }

    const dropdownStatusHandler = (value: string) => {
        const id = parseInt(value);
        setState(id);
        setFieldValue("status", id);
    }

    return (
        <Dropdown
            maxWidth={150}
            height={40}
            marginRight={0}
            paddingLeft={0}
            paddingRight={0}
            defaultValue={defaultValue}
            doubleClickDropdown={false}
            handleChange={dropdownStatusHandler}
            isTransparent={true}
        >
            {statusItems}
        </Dropdown>
    )
}

export default AddClientToGroupForm;
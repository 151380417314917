import { FormikProps } from "formik";
import React, { FC } from "react";
import Field from "../../../../components/dataEntry/inputs/Field/Field";
import Gallery from "../../../../components/dataEntry/inputs/Gallery/Gallery";
import Switch from "../../../../components/dataEntry/inputs/Switch/Switch";
import Textarea from "../../../../components/dataEntry/inputs/Textarea/Textarea";
import Button from "../../../../components/general/buttons/Button/Button";
import Row from "../../../../components/layout/Row/Row";
import Wrp from "../../../../components/layout/Wrp/Wrp";
import { SUBMIT_ACTIONS } from "../../../../common/constants/submitActions";
import { UpsellValue } from "../models/UpsellValue";

interface Props {
    formik: FormikProps<UpsellValue>;
    active?: boolean;
    isNewItemForm?: boolean;
}

const Upsell: FC<Props> = ({ formik, isNewItemForm, active }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = formik;

    const archiveButtonSubmitHandler = () => {
        setFieldValue("submitAction", SUBMIT_ACTIONS.DELETE);
        handleSubmit();
    }

    const sendButtonSubmitHandler = () => {
        setFieldValue("submitAction", SUBMIT_ACTIONS.SAVE);
        handleSubmit();
    }

    const image = values.image;
    const files = values.files;

    let gallery: (string | undefined)[] = [undefined];
    gallery = gallery.map((item, index) => image);

    let filesGallery: (File | undefined)[] = [undefined];
    filesGallery = filesGallery.map((item, index) => files && files[index]);

    return (
        <Wrp maxWidth={860} flexDirection="column">
            <Row marginBottom={20}>
                <Field
                    label="Назва послуги"
                    labelLeftShift={0}
                    maxWidth={550}
                    marginBottom={0}
                    handleChange={handleChange("name")}
                    handleBlur={handleBlur("name")}
                    value={values.name}
                    error={errors.name}
                    touched={touched.name}
                />
                <Field
                    label="Вартість"
                    type="number"
                    maxWidth={180}
                    marginBottom={0}
                    labelLeftShift={0}
                    paddingLeft={35}
                    value={values.price}
                    handleChange={handleChange("price")}
                    handleBlur={handleBlur("price")}
                    error={errors.price}
                    touched={touched.price}
                    topPositionIcon={29.2}
                    icon={<span style={{ fontSize: 14, fontWeight: "500" }}>₴</span>}
                />
                <Switch
                    value={values.confirmed}
                    title="Статус"
                    handleChange={handleChange("confirmed")}
                />
            </Row>
            <Row marginBottom={30}>
                <Textarea
                    title="Опис"
                    maxWidth={550}
                    maxHeight={90}
                    marginRight={20}
                    handleChange={handleChange("description")}
                    handleBlur={handleBlur("description")}
                    value={values.description}
                    error={errors.description}
                    touched={touched.description}
                />
                <>
                    {!isNewItemForm &&
                        <Gallery
                            title="Іконка"
                            imgs={gallery}
                            acceptFile=".PNG,.JPG,.SVG"
                            setFieldValue={setFieldValue}
                            files={filesGallery}
                            firstSize={100}
                            fieldFile="files"
                            fieldImage="image"
                            withDoubleButton={false}
                        />

                    }
                </>
            </Row>
            <Row>
                <Button
                    disabled={!isValid}
                    backgroundColor="#4775FE"
                    title="Зберегти"
                    handleClick={sendButtonSubmitHandler}
                    marginRight={20}
                />
                <>
                    {!isNewItemForm &&
                        <Button
                            backgroundColor="#A6AEC5"
                            title={active ? "Архівувати" : "Активувати"}
                            handleClick={archiveButtonSubmitHandler}
                        />
                    }
                </>
            </Row>
        </Wrp>
    );
}

export default Upsell;
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { SUBMIT_ACTIONS } from "../constants/submitActions";
import { UnknownAction } from "@reduxjs/toolkit";

function useSubmitAction(deleteRedirect?: () => void) {
    const dispatch = useAppDispatch();

    return (
        submitAction: SUBMIT_ACTIONS, 
        saveHandler?: () => UnknownAction, 
        deleteHandler?: () => UnknownAction,
        uploadImegesHandler?: () => UnknownAction
    ) => {
        switch(submitAction) {
            case SUBMIT_ACTIONS.SAVE :
                if(saveHandler) {
                    dispatch(saveHandler());
                    if(uploadImegesHandler) {
                        dispatch(uploadImegesHandler());
                    }
                }
                break;
            case SUBMIT_ACTIONS.DELETE :
                if(deleteHandler) {
                    dispatch(deleteHandler());
                    if(deleteRedirect) {
                        deleteRedirect();
                    }
                }
                break;
        }
    }
}

export default useSubmitAction
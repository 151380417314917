import { useFormik } from "formik";
import React from "react";
import { shallowEqual } from "react-redux";
import withMatch from "../../../../components/other/hoc/withMatch";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { createServicesFetch, setNewItemId } from "../../../../store/state/services/service/service.slice";
import { SUBMIT_ACTIONS } from "../../../../common/constants/submitActions";
import { convertStringToArray } from "../../../../common/helpers/convertStringToArray";
import useRedirectToNewItemPage from "../../../../common/hooks/useRedirectToNewItemPage";
import useSubmitAction from "../../../../common/hooks/useSubmitAction";
import { BasicValues } from "../models/BasicValues";
import Basic from "./Basic";
import validationSchema from "./Basic.scheme";

const NewBasicContainer = () => {
    const dispatch = useAppDispatch();
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const newItemId = useAppSelector(state => state.services.newItemId, shallowEqual);

    const payload = {
        newItemId,
        setNewItemId: (id: number | null) => {
            dispatch(setNewItemId({ id }));
        }
    }

    useRedirectToNewItemPage(payload);

    const initialValues: BasicValues = {
        name: "",
        active: true,
        categoryId: -1,
        subCategoryId: -1,
        description: "",
        duration: 1,
        durationTypeId: 1,
        typeId: 1,
        locationsIds: "",
        lessons: "Заняття 1",
        upsellsIds: "",
        confirmed: false,
        submitAction: SUBMIT_ACTIONS.SAVE,
    }

    const submitActionHandler = useSubmitAction();

    const submitFormHandler = (values: BasicValues) => {
        const {
            submitAction, name, active, categoryId, subCategoryId, description, duration,
            durationTypeId, typeId, locationsIds, lessons, upsellsIds, confirmed
        } = values;

        const locationsIdsArray = convertStringToArray(locationsIds);
        const lessonsArray = lessons.split("[/]");
        const upsellsIDs = upsellsIds.length > 0 ? upsellsIds.split(",").map(item => Number(item)) : [];

        submitActionHandler(
            submitAction,
            () => createServicesFetch(
                {
                    vendorId,
                    service: {
                        name,
                        active,
                        categoryId: Number(categoryId),
                        subCategoryId: subCategoryId && subCategoryId > -1 ? subCategoryId : null,
                        description,
                        duration,
                        durationTypeId,
                        typeId,
                        locationsIds: locationsIdsArray,
                        lessons: lessonsArray,
                        upsellsIds: upsellsIDs,
                        confirmed,
                    }
                }
            )
        );
    }

    const formik = useFormik<BasicValues>({
        initialValues,
        validationSchema,
        onSubmit: (values) => submitFormHandler(values),
    });

    return (
        <Basic
            isNewItemForm={true}
            formik={formik}
        />
    );
}

export default React.memo(withMatch(NewBasicContainer, [2, 3, 4]));
import { FormikProps } from "formik";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Message from "../../../../components/feedback/messages/Message/Message";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { setMessageVisiable } from "../../../../store/state/services/service/service.slice";
import { useMessageActions } from "../../../../common/hooks/useMessageActions";
import { BasicValues } from "../models/BasicValues";
import Basic from "./Basic";

interface Props {
    formik: FormikProps<BasicValues>;
    active: boolean;
}

const BasicMessage: FC<Props> = ({ formik, active }) => {
    const dispatch = useAppDispatch();

    const message = useAppSelector(state => state.services.message, shallowEqual);
    const visiable = useAppSelector(state => state.services.messageVisiable, shallowEqual);

    const setMessage = () => {
        dispatch(setMessageVisiable({ visiable: false }));
    }

    const visiableActions = useMessageActions(visiable, setMessage);

    return (
        <>
            <Message
                content={message || ""}
                visiable={visiableActions}
                position="top"
            />

            <Basic
                formik={formik}
                isNewItemForm={false}
                active={active}
            />
        </>
    );
}

export default React.memo(BasicMessage);
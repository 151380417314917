import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import {
    getAdditionalListFetch,
    setPageUpsells,
    setUpsells,
    setUpsellsFilter
} from "../../../../store/state/services/upsells/upsells.slice";
import { ARCHIVE_ITEMS } from "../../../../common/constants/app";
import { culcPage } from "../../../../common/helpers/culcPage";
import { Filter } from "../../../../common/models/Filters";
import DropdownFilter, { DropdownFilterItem } from "../../Basics/components/DropdownFilter/DropdownFilter";

const StatusFilter: FC = () => {
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const confirmed = useAppSelector(state => state.upsellsServices.filter.confirmed, shallowEqual);
    const active = useAppSelector(state => state.upsellsServices.filter.active, shallowEqual);
    const value = active !== undefined && !active ? ARCHIVE_ITEMS : String(confirmed);

    const page = 1;
    const totalCount = 0;
    const pageAll = 0;
    const pageSize = useAppSelector(state => state.services.pageSize, shallowEqual);
    const getAllItems = useAppSelector(state => state.services.getAllItems, shallowEqual);
    const pagesRange = culcPage(page, pageAll, totalCount, pageSize);

    const statuses: DropdownFilterItem[] = [
        {
            name: "Активні",
            value: "true",
        },
        {
            name: "Чекає підтвердження",
            value: "false",
        },
        {
            name: "Архівні",
            value: ARCHIVE_ITEMS,
        },
        {
            name: "Всі",
            value: "undefined",
        }
    ];

    const dispatch = useAppDispatch();

    const changeHandler = (value: string) => {
        let filter: Filter = {};

        if (!getAllItems) {
            filter.range = {
                from: pagesRange.from,
                to: pagesRange.to
            }
        }

        if (value.localeCompare('undefined') !== 0 && value.localeCompare(ARCHIVE_ITEMS) !== 0) {
            const status = value === "true" ? true : false;
            const active = true;

            filter.confirmed = status;
            filter.active = active;

            dispatch(setUpsellsFilter({ confirmed: status, active }));
        } else if(value.localeCompare(ARCHIVE_ITEMS) === 0) {
            const active = false;

            filter.active = active;

            dispatch(setUpsellsFilter({ confirmed: undefined, active }));
        } else {
            const active = undefined;

            filter.active = active;

            dispatch(setUpsellsFilter({ confirmed: undefined, active }));
        }

        dispatch(setUpsells({ upsells: [] }));
        dispatch(getAdditionalListFetch({ vendorId, filter }));
        dispatch(setPageUpsells({ page }));
    }

    return (
        <DropdownFilter
            label="Статус"
            changeHandler={changeHandler}
            dropdownList={statuses}
            value={value}
            maxWidth={200}
        />
    )
}

export default StatusFilter;
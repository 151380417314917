import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import Breadcrumbs from "./Breadcrumbs";
import { getTitleBreadcrumbs } from "../../../common/helpers/getTitleBreadcrumbs";
import { getItemInArrayByID } from "../../../common/helpers/getItemInArrayByID";

const BreadcrumbsContainer: FC = () => {
  const { id } = useParams();
  const itemId = Number(id);
  const state = useAppSelector((state) => state, shallowEqual);
  const roleId = useAppSelector((state) => state.user.roleId, shallowEqual);
  const location = useLocation();
  const locations = location.pathname.split("/");
  locations.shift();

  let path = [""];

  if (!locations.includes("vendors") && roleId !== 1) {
    if (
      ((locations.includes("settings") || locations.includes("services")) &&
        (locations.length === 2 || locations.length === 3)) ||
      locations.length === 1
    ) {
      path = [getTitleBreadcrumbs(locations[0])];
    } else {
      path = locations.map((item) => {
        let name = getTitleBreadcrumbs(
          item,
          locations.includes("settings") ? locations[1] : locations[0]
        );

        if (name !== "") {
          return name;
        }

        if (locations[0].localeCompare("clients") === 0) {
          const clients = state.clients.clients;
          const indexClients = clients.findIndex(
            (client) => client.clientId === itemId
          );
          if (indexClients > -1) {
            const client = clients[indexClients];

            name =
              getItemInArrayByID(client.users, "surname", client.users[0].id) ||
              "";
            name +=
              " " +
                getItemInArrayByID(client.users, "name", client.users[0].id) ||
              "";
          } else {
            name = "Клієнт";
          }
        } else if (
          locations.length > 2 &&
          locations[0].localeCompare("groups") !== 0
        ) {
          switch (locations[1]) {
            case "locations":
              name =
                getItemInArrayByID(
                  state.locations.locationsArray,
                  "name",
                  Number(locations[2])
                ) || "";
              break;
            case "basics":
              name =
                getItemInArrayByID(
                  state.services.basics,
                  "name",
                  Number(locations[2])
                ) || "";
              break;
            case "upsells":
              name =
                getItemInArrayByID(
                  state.upsellsServices.upsells,
                  "name",
                  Number(locations[2])
                ) || "";
              break;
          }
        } else {
          switch (locations[0]) {
            case "employees":
              name =
                getItemInArrayByID(
                  state.employees.employeesArray,
                  "fullname",
                  Number(locations[1])
                ) || "";
              break;
            case "groups":
              name =
                getItemInArrayByID(
                  state.groups.groups,
                  "name",
                  Number(locations[1])
                ) || "";
              break;
          }
        }

        return name;
      });
    }
  }

  return <Breadcrumbs path={path} />;
};

export default React.memo(BreadcrumbsContainer);

import * as Yup from "yup";

const timeRangeSchema = Yup.object().shape({
  from: Yup.string().nullable().required("Час початку є обов'язковим"),
  to: Yup.string()
    .nullable()
    .required("Час закінчення є обов'язковим")
    .test(
      "is-later",
      "Час закінчення повинен бути пізніше за час початку",
      function (value) {
        const { from } = this.parent;
        if (from && value) {
          return from < value;
        }
        return true;
      }
    ),
});

const calendarDaySchema = Yup.object().shape({
  checked: Yup.boolean().nullable(),
  timeRanges: Yup.object().when('checked', (checked, schema) => {
    if (checked) {
      return timeRangeSchema;
    }
    return schema.shape({
      from: Yup.string().nullable(),
      to: Yup.string().nullable(),
    });
  }),
});

export const validationSchema = Yup.object().shape({
  fullname: Yup.string()
    .matches(
      /^([^<>()[\].,;:\s@"]+)\s([^<>()[\].,;:\s@"]+)$/,
      "Не правильний формат"
    )
    .required("Введіть прізвище та ім'я"),
  roleId: Yup.number().required("Required"),
  phone: Yup.string().required("Введіть телефон"),
  email: Yup.string().email("Не правильний email").required("Введіть email"),
  calendar: Yup.object().shape({
    1: calendarDaySchema,
    2: calendarDaySchema,
    3: calendarDaySchema,
    4: calendarDaySchema,
    5: calendarDaySchema,
    6: calendarDaySchema,
    7: calendarDaySchema,
  }),
});

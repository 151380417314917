import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import Vendors from "./Vendors";
import { getVendorsFetch, setPageVendors } from "../../store/state/vendors/vendors.slice";
import { VendorsColumn } from "./models/VendorsColumn";
import usePagination from "../../common/hooks/usePagination";
import { Filter } from "../../common/models/Filters";
import culcPageTable from "../../common/helpers/culcPageTable";

interface Props {
    data: VendorsColumn[];
    modals: JSX.Element[];
}

const VendorsPagination: FC<Props> = ({ data, modals }) => {
    const dispatch = useAppDispatch();

    const page = useAppSelector(state => state.vendors.page, shallowEqual);
    const totalCount = useAppSelector(state => state.vendors.totalCount, shallowEqual);
    const pageAll = useAppSelector(state => state.vendors.pageAll, shallowEqual);
    const pageSize = useAppSelector(state => state.vendors.pageSize, shallowEqual);
    const getAllItems = useAppSelector(state => state.vendors.getAllItems, shallowEqual);

    const setPage = (filter: Filter | undefined, pageNumber: number) => {
        dispatch(getVendorsFetch({ filter }));
        dispatch(setPageVendors({ page: pageNumber }))
    };

    const pagination = usePagination({
        getAllItems,
        pageAll,
        page,
        totalCount,
        pageSize,
        setPage
    });

    const pagesRange = pagination.pagesRange;

    const pageTable = culcPageTable<VendorsColumn>(data, pagesRange.from, pagesRange.to);

    return (
        <Vendors
            data={pageTable}
            modals={modals}
            page={page}
            pages={pagination.pages}
            handleRightButtonPagination={pagination.handleRightButtonPagination}
            handleLeftButtonPagination={pagination.handleLeftButtonPagination}
        />
    );
}

export default VendorsPagination;
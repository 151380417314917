import { createSlice } from '@reduxjs/toolkit';
import { UpsellState } from './models/UpsellsState';
import createAdditionalFetchReducer from './reducer/createAdditional/createAdditionalFetch';
import createAdditionalSuccessReducer from './reducer/createAdditional/createAdditionalSuccess';
import updateAdditionalFetchReducer from './reducer/updateAdditional/updateAdditionalFetch';
import updateAdditionalSuccessReducer from './reducer/updateAdditional/updateAdditionalSuccess';
import getAdditionalFetchReducer from './reducer/getAdditional/getAdditionalFetch';
import getAdditionalSuccessReducer from './reducer/getAdditional/getAdditionalSuccess';
import getAdditionalListFetchReducer from './reducer/getAdditionalList/getAdditionalListFetch';
import getAdditionalListSuccessReducer from './reducer/getAdditionalList/getAdditionalListSuccess';
import deleteAdditionalFetchReducer from './reducer/deleteAdditional/deleteAdditionalFetch';
import deleteAdditionalSuccessReducer from './reducer/deleteAdditional/deleteAdditionalSuccess';
import setErrorReducer from './reducer/setError';
import setPageUpsellsReducer from './reducer/setPageUpsells';
import setMessageReducer from './reducer/setMessage';
import setMessageVisiableReducer from './reducer/setMessageVisiable';
import setNewItemIdReducer from './reducer/setNewItemId';
import resetUpsellsReducer from './reducer/resetUpsells';
import { Filter } from '../../../../common/models/Filters';
import setUpsellsReducer from './reducer/setUpsells';
import setUpsellsFilterReducer from './reducer/setUpsellsFilter';
import changeUpsellActivityFetchReducer from './reducer/changeUpsellActivity/changeUpsellActivityFetch';
import changeUpsellActivitySuccessReducer from './reducer/changeUpsellActivity/changeUpsellActivitySuccess';
import uploadUpsellIconSuccessReducer from './reducer/uploadUpsellIcon/uploadUpsellIconSuccess';
import uploadUpsellIconFetchReducer from './reducer/uploadUpsellIcon/uploadUpsellIconFetch';

const filter: Filter = {
    confirmed: undefined,
}

export const initialState: UpsellState = {
    upsells: [],
    filter,
    isLoading: false,
    isError: null,
    message: null,
    messageVisiable: false,
    getAllItems: true,
    pageAll: 0,
    pageSize: 15,
    page: 1,
    totalCount: 0,
    newItemId: null,
}

export const AdditionalSlice = createSlice({
    name: 'upsellsServices',
    initialState,
    reducers: {
        createAdditionalFetch: createAdditionalFetchReducer,
        createAdditionalSuccess: createAdditionalSuccessReducer,
        updateAdditionalFetch: updateAdditionalFetchReducer,
        updateAdditionalSuccess: updateAdditionalSuccessReducer,
        getAdditionalFetch: getAdditionalFetchReducer,
        getAdditionalSuccess: getAdditionalSuccessReducer,
        getAdditionalListFetch: getAdditionalListFetchReducer,
        getAdditionalListSuccess: getAdditionalListSuccessReducer,
        deleteAdditionalFetch: deleteAdditionalFetchReducer,
        deleteAdditionalSuccess: deleteAdditionalSuccessReducer,
        setError: setErrorReducer,
        setPageUpsells: setPageUpsellsReducer,
        setMessage: setMessageReducer,
        setMessageVisiable: setMessageVisiableReducer,
        setNewItemId: setNewItemIdReducer,
        resetUpsells: resetUpsellsReducer,
        setUpsells: setUpsellsReducer,
        setUpsellsFilter: setUpsellsFilterReducer,
        changeUpsellActivityFetch: changeUpsellActivityFetchReducer,
        changeUpsellActivitySuccess: changeUpsellActivitySuccessReducer,
        uploadUpsellIconFetch: uploadUpsellIconFetchReducer,
        uploadUpsellIconSuccess: uploadUpsellIconSuccessReducer,
    },
});

export const {
    createAdditionalFetch,
    createAdditionalSuccess,
    updateAdditionalFetch,
    updateAdditionalSuccess,
    getAdditionalFetch,
    getAdditionalSuccess,
    getAdditionalListFetch,
    getAdditionalListSuccess,
    deleteAdditionalFetch,
    deleteAdditionalSuccess,
    setError,
    setMessage,
    setMessageVisiable,
    setPageUpsells,
    setNewItemId,
    resetUpsells,
    setUpsells,
    setUpsellsFilter,
    changeUpsellActivityFetch,
    changeUpsellActivitySuccess,
    uploadUpsellIconFetch,
    uploadUpsellIconSuccess,
} = AdditionalSlice.actions

export default AdditionalSlice.reducer
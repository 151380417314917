import React, { FC } from "react";
import { PagePagination } from "../../components/dataDisplay/table/models/PagePagination";
import Table from "../../components/dataDisplay/table/Table";
import { DATA_INDEX_VENDORS } from "../../common/constants/tables";
import { VendorsColumn } from "./models/VendorsColumn";

interface Props {
    data: Array<VendorsColumn>
    modals: JSX.Element[];
    page: number;
    pages: PagePagination[];
    handleLeftButtonPagination: () => void;
    handleRightButtonPagination: () => void;
}

const Vendors: FC<Props> = ({data, modals, page, pages, handleLeftButtonPagination, handleRightButtonPagination}) => {
    return (
        <>  
            <Table 
                data={data} 
                columns={DATA_INDEX_VENDORS}
                page={page}
                pages={pages}
                handleLeftButtonPagination={handleLeftButtonPagination}
                handleRightButtonPagination={handleRightButtonPagination} 
                paginated={true}
            />
            {modals}
        </>
    );
}

export default Vendors;
import React, { FC, MouseEvent } from "react";
import Wrp from "../../../components/layout/Wrp/Wrp";
import Table from "../../../components/dataDisplay/table/Table";
import { DATA_INDEX_LOCATIONS } from "../../../common/constants/tables";
import { LocationsColumns } from "./models/LocationsColumns";
import { PagePagination } from "../../../components/dataDisplay/table/models/PagePagination";

interface Props {
    data: Array<LocationsColumns>;
    page: number;
    pages: PagePagination[];
    handleLeftButtonPagination: () => void;
    handleRightButtonPagination: () => void;
    handleClickRow: (item: any, event: MouseEvent<HTMLTableRowElement>) => void;
}

const Locations: FC<Props> = ({data, page, pages, handleLeftButtonPagination, handleRightButtonPagination, handleClickRow}) => { 
    return (
        <Wrp flexDirection="column" maxWidth={1240}>
            <Table 
                handleClickRow={handleClickRow}
                heightRow={50}
                leftShiftContentRow={25} 
                data={data} 
                columns={DATA_INDEX_LOCATIONS} 
                paginated={true}
                page={page}
                pages={pages}
                handleLeftButtonPagination={handleLeftButtonPagination}
                handleRightButtonPagination={handleRightButtonPagination}
            />
        </Wrp>
    );
}

export default Locations;
import React, { FC, FocusEvent } from "react";
import { shallowEqual } from "react-redux";
import TagInput from "../../../../../../components/dataEntry/inputs/TagInput/TagInput";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { locationNameAdapter } from "../../../../../../common/adapters/LocationNameAdapter";

interface Props {
    locationsIdsValues: string;
    error: string | undefined;
    touched: boolean | undefined;
    handleBlur: (event: FocusEvent<HTMLElement>) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    marginRight?: number;
}

const LocationsIdsTagInput: FC<Props> = ({ locationsIdsValues, error, touched, handleBlur, setFieldValue, marginRight }) => {
    let locations = useAppSelector(state => state.locations.locationsArray, shallowEqual);
    const locationsList = locations.map(location => ({ id: location.id, name: location.name }));

    const activelocations = locationsIdsValues.length > 0 ? locationsIdsValues.split(',')
        .map(locationId => (
            {
                id: Number(locationId),
                name: locationNameAdapter(locations, Number(locationId))
            }
        )) : [];

    const changeHandler = (locationsIds: string) => {
        setFieldValue("locationsIds", locationsIds);
    }

    return (
        <TagInput
            label="Локація"
            maxWidth={470}
            tagsArray={locationsList}
            activeTagsArray={activelocations}
            handleChange={changeHandler}
            handleBlur={handleBlur}
            error={error}
            touched={touched}
            values={locationsIdsValues}
            marginRight={marginRight}
        />
    )
}

export default LocationsIdsTagInput;
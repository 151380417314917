import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { SalaryForServices } from "../state/employees/models/SalaryForServices";
import { CalendarDay } from "../../screens/Employee/models/EmployeeForm";

export interface IEmployee {
  id: number;
  image: string;
  roleId: number;
  fullname: string;
  email: string;
  phone: string;
  startDate: string;
  salary: number;
  locationsIds: number[];
  salaryForServices: SalaryForServices[];
  active: boolean;
  calendar: {
    [key: number]: CalendarDay;
  };
}

export interface IGetEmployeesListArg {
  vendorId: number;
}

export interface IGetEmployeesArg {
  vendorId: number;
  employeeId: number;
}

export interface EmployeeForm {
  fullname: string;
  roleId: number;
  phone: string;
  email: string;
  calendar: {
    [key: number]: CalendarDay;
  };
  // active: boolean;
  // time: string;
}

export interface IUpdateEmployee {
  vendorId: number;
  employeeId: number;
  data: EmployeeForm;
}

export const EmployeeTag = "Employee";

export const employeeApi = createApi({
  reducerPath: "employeeApi",
  baseQuery,
  tagTypes: [EmployeeTag],
  endpoints: (builder) => ({
    getEmployees: builder.query<IEmployee[], IGetEmployeesListArg>({
      query: ({ vendorId }) => `vendors/${vendorId}/teachers`,
    }),
    getEmployee: builder.query<IEmployee, IGetEmployeesArg>({
      query: ({ vendorId, employeeId }) =>
        `/vendors/${vendorId}/employees/${employeeId}`,
    }),
    updateEmployee: builder.mutation<IEmployee, IUpdateEmployee>({
      query: ({ vendorId, employeeId, data }) => ({
        url: `/vendors/${vendorId}/employees/${employeeId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [EmployeeTag],
    }),
    sendPasswordToAdmin: builder.mutation<void, IGetEmployeesArg>({
      query: ({ vendorId, employeeId }) => ({
        url: `/vendors/${vendorId}/employees/${employeeId}/password`,
        method: "PUT",
      }),
      invalidatesTags: [EmployeeTag],
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useGetEmployeeQuery,
  useUpdateEmployeeMutation,
  useSendPasswordToAdminMutation,
} = employeeApi;

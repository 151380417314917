import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { configListAdapter } from "../../../../../../common/adapters/ConfigListAdapter";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../common/constants/defaultValue";

interface Props {
    durationTypeId: number;
    error: string | undefined;
    touched: boolean | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void
}

const DurationTypeDropdown: FC<Props> = ({ durationTypeId, error, touched, setFieldValue, setFieldTouched }) => {
    let durationTypes = useAppSelector(state => state.config.vendorConfig?.service.duration, shallowEqual);
    const durationTypesList = configListAdapter(durationTypes);
    
    const indexDurationTypes = durationTypesList.findIndex(durationType => durationType.id === durationTypeId);
    const defaultDurationType = durationTypesList[indexDurationTypes];

    const defaultName = defaultDurationType?.name;
    const valueInput = (defaultDurationType && String(defaultDurationType.id)) || DEFAULT_VALUES_DROPDOWN.valueInput;
    const valueText = defaultName || DEFAULT_VALUES_DROPDOWN.valueText;

    const defaultValueDurationType: DefaultValue = {
        valueInput,
        valueText,
    }

    const durationTypeItems = durationTypesList.map(durationType => {
        const name = durationType.name;
        const id = String(durationType.id);
        
        return (
            <DropdownItem
                item={name}
                valueInput={id}
                valueText={name}
            />
        )
    });

    const changeHandler = (durationTypeId: string) => {
        setFieldValue("durationTypeId", parseInt(durationTypeId));
    }

    const blurHandler = (touched: boolean) => {
        setFieldTouched("durationTypeId", touched)
    }

    return (
        <Dropdown
            label="Тривалість"
            maxWidth={100}
            marginRight={20}
            defaultValue={defaultValueDurationType}
            handleChange={changeHandler}
            handleBlur={blurHandler}
            error={error}
            touched={touched}
            doubleClickDropdown={false}
        >
            {durationTypeItems}
        </Dropdown>
    )
}

export default DurationTypeDropdown;
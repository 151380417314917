import { Form, Select } from "antd";
import { FC } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { CalendarEventForm } from "../../../../models/CalendarEventForm";

interface SelectEmployeesProps {
  errors: FieldErrors;
  control: Control<CalendarEventForm>;
}

const options = [{ value: "employee1", label: <span>employee1</span> }];

export const SelectEmployees: FC<SelectEmployeesProps> = ({
  control,
  errors,
}) => {
  return (
    <Form.Item
      label="Виберіть викладачів"
      validateStatus={errors.employeesIds ? "error" : ""}
      help={<p>{`${errors.employeesIds && errors.employeesIds.message}`}</p>}
    >
      <Controller
        name="employeesIds"
        control={control}
        render={({ field }) => (
          <Select mode="multiple" placeholder="Виберіть викладачів" {...field}>
            {options.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )}
      />
    </Form.Item>
  );
};

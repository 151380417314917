import React, { MouseEvent } from "react";
import { shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import RectProgress from "../../../components/dataDisplay/progress/Rect/RectProgress";
import Avatar from "../../../components/dataDisplay/user/Avatar/Avatar";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { configAdapter } from "../../../common/adapters/ConfigAdapter";
import { BasicsColumn } from "./models/BasicsColumn";
import LocationView from "../../../components/dataDisplay/LocationView/LocationView";
import { locationNameAdapter } from "../../../common/adapters/LocationNameAdapter";
import IconFont from "../../../components/general/icons/IconFont/IconFont";
import ButtonIcon from "../../../components/general/buttons/ButtonIcon/ButtonIcon";
import StatusView from "../../../components/dataDisplay/table/views/StatusView/StatusView";
import NameView from "../../../components/dataDisplay/table/views/NameView/NameView";
import BasicsPagination from "./Basics.pagination";

const BasicsContainer = () => {
    let navigation = useNavigate();

    const basics = useAppSelector(state => state.services.basics, shallowEqual)

    const basicsData = useAppSelector(state => state.services.pageData, shallowEqual);
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const durationTypes = useAppSelector(state => state.config.vendorConfig?.service.duration, shallowEqual);
    const types = useAppSelector(state => state.config.vendorConfig?.service.types, shallowEqual);
    const categoris = useAppSelector(state => state.config.vendorConfig?.service.categories, shallowEqual);

    const locations = useAppSelector(state => state.locations.locationsArray, shallowEqual);

    const data: BasicsColumn[] = basics.map(basic => {
        const serviceId = basic.id;

        const basicsDataIndex = basicsData.findIndex(basicData => basicData.id === serviceId);
        const basicData = basicsData[basicsDataIndex];

        const locationsIds = basicData?.locationsIds || [];
        const locationsIdsLength = locationsIds.length;
        const location = locationsIdsLength > 1 ?
            <LocationView
                count={locationsIdsLength}
                link="settings/locations"
                locations={locationsIds.map(elem => ({ id: elem, name: locationNameAdapter(locations, elem) }))}
            /> : locationNameAdapter(locations, locationsIds[0]);

        const name = basicData?.name || "";

        const durationValue = basicData?.duration || 0;
        const durationTypeId = basicData?.durationTypeId || -1;
        const duration = `${durationValue} ${durationTypes && configAdapter(durationTypes, durationTypeId)}`;

        const typeId = basicData?.typeId || -1;
        const durationType = `${types && configAdapter(types, typeId)}`;

        const categoryId = basicData?.categoryId;
        const category = `${categoris && configAdapter(categoris, categoryId)}`;

        const confirmed = basicData?.confirmed !== undefined ?  basicData.confirmed : true;
        const active = basicData?.active !== undefined ? basicData?.active : true;

        const fullness = Math.floor(basicData?.fullness || 0);

        const editClickButton = () => {
            navigation(`${serviceId}`, {});
        }

        return {
            id: serviceId,
            miniature:
                <Avatar
                    name={name}
                    width={50}
                    height={50}
                    background="#A6AEC5"
                    backgroundName="#A6AEC5"
                    fontSize={18}
                    lineHeight={21}
                    maxHeightImg={"100%"}
                    maxWidthImg={"100%"}
                />,
            name: <NameView name={name} />,
            duration,
            durationType,
            category,
            locations: location,
            status: <StatusView status={active ? Number(confirmed) : 2} />,
            occupancy: <RectProgress progress={fullness} />,
            edit:
                <ButtonIcon
                    icon={<IconFont color="#A6AEC5" iconClass="icon-edit" fontSize={13} />}
                    handleClick={editClickButton}
                />,
        }
    });

    const clickRowTableHandler = (item: any, event: MouseEvent<HTMLTableRowElement>) => {
        navigation(`${item.id}`, {})
    }

    return (
        <BasicsPagination
            handleClickRow={clickRowTableHandler}
            data={data}
            vendorId={vendorId}
        />
    );
}

export default BasicsContainer;
import React, { FC } from "react";
import Button from "../../../../components/general/buttons/Button/Button";
import IconFont from "../../../../components/general/icons/IconFont/IconFont";
import NotificationsBlock from "../../../../components/layout/NotificationsBlock/NotificationsBlock";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { changeNotificationsStatusFetch, NotificationStatuses } from "../../../../store/state/notifications/notifications.slice";
import { Notification as NotificationType } from "../../../../store/state/notifications/models/Notification";
import { DateApi } from "../../../../services/Date/Date";
import { TYPES } from "../../../../common/constants/types";
import Notification from "../../../../components/feedback/notifications/Notification/Notification";
import Paragraph from "../../../../components/general/typography/Paragraph/Paragraph";

interface Props {
    vendorId: number;
    active: NotificationType[];
}

const NotificationsActive: FC<Props> = ({ vendorId, active }) => {
    const dispatch = useAppDispatch();

    const notifications = active.map((notification, index) => {
        let marginBottom = index !== active.length - 1 ? 15 : 0;
        const message = notification.message;
        const datetime = DateApi.parseDateTime(notification.date);
        const date = `${DateApi.parseDateStringPrint(datetime[0])} ${datetime[1]}`;
        const origin = notification.typeId === TYPES.SYSTEM ? "Система" : "Клієнти";

        const prevStatusId = notification.statusId;
        const notificationsIds = [notification.id];

        const clickActiveButtonHandler = () => {
            dispatch(changeNotificationsStatusFetch({ vendorId, statusId: NotificationStatuses.ARCHIVE, prevStatusId, notificationsIds }));
        }
        const clickSelectButtonHandler = () => {
            dispatch(changeNotificationsStatusFetch({ vendorId, statusId: NotificationStatuses.SELECTED, prevStatusId, notificationsIds }));
        }

        return (
            <Notification
                marginBottom={marginBottom}
                notification={<Paragraph content={message} />}
                datatime={date}
                origin={origin}
                icon={<></>}
                clickActiveButtonHandler={clickActiveButtonHandler}
                clickSelectButtonHandler={clickSelectButtonHandler}
            />
        );
    });

    const clickButtonAllArchive = () => {
        const notificationsIds = active.map(notification => notification.id);

        dispatch(changeNotificationsStatusFetch({
            vendorId,
            statusId: NotificationStatuses.ARCHIVE,
            prevStatusId: NotificationStatuses.ACTIVE,
            notificationsIds
        }));
    }

    const button = (
        <Button
            backgroundColor="transperent"
            colorText="#A6AEC5"
            title="Приховати всі"
            handleClick={clickButtonAllArchive}
            icon={
                <IconFont
                    iconClass="icon-vector-8"
                    color="#A6AEC5"
                    fontSize={15}
                />
            }
        />
    );

    return (
        NotificationsActive.length > 0 ?
            <NotificationsBlock
                title="Активні"
                marginBottom={30}
                button={button}
            >
                {notifications}
            </NotificationsBlock>
        :
            <></>
    );
}

export default NotificationsActive;
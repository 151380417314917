/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-refresh/only-export-components */
import React, { FC, useEffect, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Message from "../../../components/feedback/messages/Message/Message";
import TabPanelItem from "../../../components/navigation/TabPanel/TabPaneltem";
import withMatch from "../../../components/other/hoc/withMatch";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import {setMessageVisiableClients } from "../../../store/state/clients/clients.slice";
import { useMessageActions } from "../../../common/hooks/useMessageActions";
import useNoMatch from "../../../common/hooks/useNoMatch";
import Client from "./Client";

interface State {
    name: string;
    active: boolean;
    url: string;
}

const ClientContainer: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const locations = location.pathname.split("/");
    locations.shift();
    const { id } = useParams();
    const clientId = Number(id);
    const clients = useAppSelector(state => state.clients.pageData, shallowEqual);
    const indexClient = clients.findIndex(client => client.clientId === clientId);
    const client = clients[indexClient];
 
    const noMatch = useNoMatch(client);

    const message = useAppSelector(state => state.clients.message, shallowEqual);
    const visiable = useAppSelector(state => state.clients.messageVisiable, shallowEqual);
   

    const [pages, setState] = useState<Array<State>>([
        {
            name: "Спілкування",
            active: locations.length > 2 && locations[2] === "communication",
            url: "communication"
        },
        {
            name: "Гроші",
            active: locations.length > 2 && locations[2] === "money",
            url: "money"
        },
    ]);

    useEffect(() => {
        setState([
            {
                name: "Спілкування",
                active: locations.length > 2 && locations[2] === "communication",
                url: "communication"
            },
            {
                name: "Гроші",
                active: locations.length > 2 && locations[2] === "money",
                url: "money"
            },
        ]);
    }, [location]);

    const panelItems = pages.map((page, index) => {
        const clickTabHandler = () => {
            navigate(`/clients/${id}/${page.url}`);
        }

        return (
            <TabPanelItem name={page.name} active={page.active} handleClick={clickTabHandler} />
        );
    });

    const setMessage = () => {
        dispatch(setMessageVisiableClients({visiable: false}));
    }
    
    const visiableActions = useMessageActions(visiable, setMessage);

    if (noMatch.isNoMatch) {
        return noMatch.noMatchComponent
    }

    return (
        <>
            <Message
                content={message || ""}
                visiable={visiableActions}
                position="top"
            />
            
            <Client
                locations={locations}
                panelItems={panelItems}
            />
        </>
    );
}

export default React.memo(withMatch(ClientContainer, [2, 3, 4]));
import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";
import ButtonIcon from "../../../general/buttons/ButtonIcon/ButtonIcon";
import IconFont from "../../../general/icons/IconFont/IconFont";
import styles from "./TagInput.module.css";
import classNames from "classnames";
import "./transition.css";
import useDebounce from "../../../../common/hooks/useDebounce";

const cx = classNames.bind(styles);

interface Tag {
  id: number;
  name: string;
}

interface Props {
  label?: string;
  tagsArray: Array<Tag>;
  maxWidth?: number;
  activeTagsArray?: Array<Tag>;
  keyIndex?: string;
  marginRight?: number;
  handleChange?: (value: string) => void;
  handleBlur?: (event: FocusEvent<HTMLElement>) => void;
  handleFocus?: (event: FocusEvent<HTMLElement>) => void;
  error?: string | undefined;
  touched?: boolean | undefined;
  values?: string;
}

const TAGS_KEY = "TAGS_";
const ENTER_KEY = "Enter";
const SPACE_KEY = " ";

const TagInput: FC<Props> = ({
  values,
  maxWidth = 470,
  tagsArray,
  activeTagsArray = [],
  keyIndex,
  label,
  marginRight,
  handleChange,
  handleBlur,
  error,
  touched,
}) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [zIndexValues, setZIndexValues] = useState({
    zIndexBlock: 5,
    zIndexList: 4,
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [list, setList] = useState<JSX.Element[] | undefined>([]);
  const debouncedValue = useDebounce(inputValue, 500);

  const blurTagInputHandler = () => {
    setToggle(false);
    if (list && list.length < 1) {
      setZIndexValues({ zIndexList: 4, zIndexBlock: 5 });
    }
  };

  const clickTagInputHandler = () => {
    inputRef.current?.focus();
    setZIndexValues({ zIndexList: 6, zIndexBlock: 7 });
  };

  const changeInputTagHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    setInputValue(targetValue);

    if (targetValue !== "") {
      setToggle(true);
    }
  };

  const keyDownInputTagHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    if (key === ENTER_KEY || key === SPACE_KEY) {
      const index = tagsArray.findIndex((item) => item.name === inputValue);
      if (index !== -1) {
        const indexTags = activeTagsArray.findIndex(
          (item) => item.id === tagsArray[index].id
        );

        if (indexTags === -1 && handleChange) {
          handleChange(
            [...activeTagsArray, { ...tagsArray[index] }]
              .map((tag) => tag.id)
              .sort((tag, nextTag) => tag - nextTag)
              .join(",")
          );
        }

        setInputValue("");
        setToggle(false);
      }
    }
  };

  const clickCloseAllButtonHendler = () => {
    if (handleChange) {
      handleChange("");
    }
  };

  const tags =
    activeTagsArray.length !== tagsArray.length
      ? activeTagsArray.map((item, index) => {
          const clickCloseButtonHendler = () => {
            if (handleChange) {
              handleChange(
                activeTagsArray
                  .filter((val) => val.id !== item.id)
                  .map((tag) => tag.id)
                  .sort((tag, nextTag) => tag - nextTag)
                  .join(",")
              );
            }
          };

          return (
            <div
              key={TAGS_KEY + index + "_" + keyIndex}
              className={styles.tagItem}
            >
              {item.name}
              <div className={styles.closeBtnBlock}>
                <ButtonIcon
                  icon={
                    <IconFont
                      fontSize={11}
                      iconClass="icon-plus"
                      color="#FFF"
                    />
                  }
                  handleClick={clickCloseButtonHendler}
                />
              </div>
            </div>
          );
        })
      : tagsArray.length > 0 && (
          <div
            key={TAGS_KEY + "ALL" + "_" + keyIndex}
            className={styles.tagItem}
          >
            {"Всі"}
            <div className={styles.closeBtnBlock}>
              <ButtonIcon
                icon={
                  <IconFont fontSize={11} iconClass="icon-plus" color="#FFF" />
                }
                handleClick={clickCloseAllButtonHendler}
              />
            </div>
          </div>
        );

  const clickAllItemHandler = () => {
    if (handleChange) {
      handleChange(
        [...tagsArray]
          .map((tag) => tag.id)
          .sort((tag, nextTag) => tag - nextTag)
          .join(",")
      );
    }
    setInputValue("");
  };

  const AllItem = (
    <li
      key={TAGS_KEY + "ALL_" + "SEARCH_ITEM_" + keyIndex}
      className={styles.serchListItem}
      onClick={clickAllItemHandler}
    >
      {"Всі"}
    </li>
  );

  useEffect(() => {
    const searchParams = tagsArray.filter((tag) => {
      const name = tag.name.toUpperCase();
      const inputName = inputValue.toUpperCase();
      const isActiveTag =
        activeTagsArray.findIndex((activeTag) => activeTag.id === tag.id) ===
        -1;

      return name.includes(inputName) && isActiveTag;
    });

    const searchList = searchParams.map((item, index) => {
      const clickSearchListItemHandler = () => {
        const indexTag = tagsArray.findIndex((elem) => elem.id === item.id);
        const inx = activeTagsArray.findIndex(
          (elem) => elem.id === tagsArray[indexTag].id
        );

        if (inx === -1 && handleChange) {
          handleChange(
            [...activeTagsArray, { ...tagsArray[indexTag] }]
              .map((tag) => tag.id)
              .sort((tag, nextTag) => tag - nextTag)
              .join(",")
          );
        }

        setInputValue("");
        setToggle(false);
      };

      return (
        <li
          key={TAGS_KEY + index + "_" + "SEARCH_ITEM_" + keyIndex}
          className={styles.serchListItem}
          onClick={clickSearchListItemHandler}
        >
          {item.name}
        </li>
      );
    });

    setList(searchList);
  }, [debouncedValue]);

  const visiableList = list && list.length > 0;

  return (
    <div
      className={cx(styles.tagInputBlock)}
      style={{ maxWidth, marginRight }}
      onClick={clickTagInputHandler}
      onBlur={blurTagInputHandler}
    >
      {label && <p className={styles.tagInputTitle}>{label}</p>}
      <div
        onBlur={handleBlur}
        className={cx(styles.tagInput, error && touched && styles.error)}
        style={{ zIndex: zIndexValues.zIndexBlock }}
      >
        {tags}
        <input
          ref={inputRef}
          className={styles.tagTextInput}
          type="text"
          onKeyDown={keyDownInputTagHandler}
          onChange={changeInputTagHandler}
          value={inputValue}
        />
      </div>

      <input type="hidden" value={values} />

      <CSSTransition
        in={toggle && visiableList}
        timeout={400}
        unmountOnExit
        classNames="search-tags-list"
      >
        <>
          {
            <ul
              style={{ zIndex: zIndexValues.zIndexList }}
              className={styles.serchListTags}
            >
              {AllItem}
              {list}
            </ul>
          }
        </>
      </CSSTransition>
    </div>
  );
};

export default TagInput;

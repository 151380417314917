import { PayloadAction } from "@reduxjs/toolkit";
import { Visiable } from "../../../../common/models/Visiable";
import { GroupsState } from "../models/GroupsState";

const showStudentVisitForm = (state: GroupsState, action: PayloadAction<Visiable>) => {
    const { visiable } = action.payload;

    state.showStudentVisitForm = visiable;
}

export default showStudentVisitForm;
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { setDefaultValueClients } from "../../store/state/clients/clients.slice";
import { setDefaultValueFinance } from "../../store/state/finance/finance.slice";
import { setDefaultValueGroups } from "../../store/state/groups/groups.slice";

type TDefaultSettings = () => ReturnType<typeof setTimeout>;

export default function useDefaultSettings(delay: number = 0): TDefaultSettings {
    const dispatch = useAppDispatch();

    return () => setTimeout(() => {
        dispatch(setDefaultValueGroups({
            searchCleints: true,
            searchGroups: true,
            selectedService: true,
            searchClientsFormVisiable: true,
            createClientFormVisiable: true,
            addStudentToGroupFormVisiable: true,
            showStudentVisitForm: true
        }));

        dispatch(setDefaultValueClients({
            visiableCommentForm: true,
            visiableTransactionForm: true,
            clientsPages: false,
            transactionsPages: true
        }));

        dispatch(setDefaultValueFinance({
            visiableAddForm: true,
            visiableExchangeForm: true,
            visiablePaymentForm: true,
            counterpartis: true,
            counteragents: true,
        }));
    }, delay);
}
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import Money from "./Money";
import { setMessageVisiable } from "../../../store/state/money/money.slice";
import Message from "../../../components/feedback/messages/Message/Message";
import { useMessageActions } from "../../../common/hooks/useMessageActions";
import { FormikProps } from "formik";
import { MoneyValues } from "./models/MoneyValues";

interface Props {
    formik: FormikProps<MoneyValues>;
}

const MoneyContainer: FC<Props> = ({ formik }) => {
    const dispatch = useAppDispatch();
 
    const message = useAppSelector(state => state.money.message, shallowEqual);
    const visiable = useAppSelector(state => state.money.messageVisiable, shallowEqual);

    const setMessage = () => {
        dispatch(setMessageVisiable({visiable: false}));
    }
    
    const visiableActions = useMessageActions(visiable, setMessage);

    return (
        <>
            <Message
                content={message || ""}
                visiable={visiableActions}
                position="top"
            />

            <Money 
                formik={formik} 
            />
        </>
    )
}

export default React.memo(MoneyContainer);
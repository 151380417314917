import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export interface IGetStudentsListArg {
    vendorId: number;
    search: string;
}

export const StudentTag = "Student";

export interface IStudent {
    id: number;
    fullname: string;
    email: string;
    phone: string;
    roleId: number;
}

export const studentApi = createApi({
    reducerPath: 'studentsApi',
    baseQuery,
    tagTypes: [StudentTag],
    endpoints: (builder) => ({
        getStudents: builder.query<IStudent[], IGetStudentsListArg>({
            query: ({ vendorId, search }) => ({ url: `vendors/${vendorId}/users/search`, method: 'POST', body: { roleId: 7, value: search } }),
        }),
    }),
});

export const { useGetStudentsQuery } = studentApi;


import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import Field from "../../../../../../components/dataEntry/inputs/Field/Field";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { discountsAdapter } from "../../../../../../common/adapters/DiscountsAdapter";

interface Props {
    sum: number;
    error: string | undefined;
    touched: boolean | undefined;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const PriceField: FC<Props> = ({
    sum,
    error,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
}) => {
    let { id } = useParams();
    const clientId = (id && parseInt(id)) || -1;

    let clients = useAppSelector(state => state.clients.pageData, shallowEqual);
    let indexClient = clients.findIndex(client => client.clientId === clientId);
    let discountId = clients[indexClient].discountId || -1;

    const discounts = useAppSelector(state => state.money.discounts, shallowEqual);
    const discountValue = discountsAdapter(discounts, discountId);

    let discount: number | undefined = undefined;

    if(discountValue) {
        discount = discountValue.value;
    }

    const changeHandler = (value: number | string) => {
        setFieldValue("sum", value);
    }

    return (
        <Field
            label="Сума"
            type="number"
            maxWidth={210}
            marginBottom={0}
            labelLeftShift={0}
            paddingLeft={35}
            topPositionIcon={29.2}
            icon={<span style={{ fontSize: 14, fontWeight: "500" }}>₴</span>}
            value={sum}
            handleBlur={handleBlur("sum")}
            handleChange={handleChange("sum")}
            touched={touched}
            error={error}
            discount={discount}
            setFieldValue={changeHandler}
        />
    )
}

export default PriceField;
const BASE_URL = import.meta.env.REACT_APP_NODE_ENV === "production" ? "https://api.reddy.com.ua"
    : "https://reddy-api.kostyakimenko.workers.dev";
const SUPPORT_ADMIN_EMAIL = "support@reddy.com.ua";
const DOWNLOAD_CLIENTS_CSV_TEMPLATE = BASE_URL + "/files/downloads/vendors/clients.csv";
const DESTROY_SESSION = "user/userExit";
const SERVER_ERROR_500 = 500;
const ARCHIVE_ITEMS = "archive";

export { 
    BASE_URL, 
    SUPPORT_ADMIN_EMAIL, 
    DOWNLOAD_CLIENTS_CSV_TEMPLATE, 
    DESTROY_SESSION,
    SERVER_ERROR_500,
    ARCHIVE_ITEMS,
};

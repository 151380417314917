import React, { FC, MouseEvent } from "react"
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { getActiveServicesListFetch, setPageActiveSrvices } from "../../../../store/state/dashboard/dashboard.slice";
import culcPageTable from "../../../../common/helpers/culcPageTable";
import usePagination from "../../../../common/hooks/usePagination";
import { Filter } from "../../../../common/models/Filters";
import { BasicsColumn } from "../../../Services/Basics/models/BasicsColumn";
import ServicesTableBlock from "./ServicesTableBlock";

interface Props {
    data: BasicsColumn[];
    handleClickRow: (item: any, event: MouseEvent<HTMLTableRowElement>) => void;
    vendorId: number;
}

const ServicesTableBlockPagination: FC<Props> = ({ data, handleClickRow, vendorId }) => {
    const dispatch = useAppDispatch();

    const page = useAppSelector(state => state.dashdoard.activeSrvicePage.page, shallowEqual);
    const totalCount = useAppSelector(state => state.dashdoard.activeSrvicePage.totalCount, shallowEqual);
    const pageAll = useAppSelector(state => state.dashdoard.activeSrvicePage.pageAll, shallowEqual);
    const pageSize = useAppSelector(state => state.dashdoard.activeSrvicePage.pageSize, shallowEqual);
    const getAllItems = useAppSelector(state => state.dashdoard.activeSrvicePage.getAllItems, shallowEqual);

    const setPage = (filter: Filter | undefined, pageNumber: number) => {
        dispatch(getActiveServicesListFetch({ vendorId, filter }));
        dispatch(setPageActiveSrvices({ page: pageNumber }));
    };

    const pagination = usePagination({
        getAllItems,
        pageAll,
        page,
        totalCount,
        pageSize,
        setPage,
        active: true,
    });

    const pagesRange = pagination.pagesRange;

    const pageTable = culcPageTable<BasicsColumn>(data, pagesRange.from, pagesRange.to);

    return (
        <ServicesTableBlock
            data={pageTable}
            page={page}
            pages={pagination.pages}
            handleLeftButtonPagination={pagination.handleLeftButtonPagination}
            handleRightButtonPagination={pagination.handleRightButtonPagination}
            handleClickRow={handleClickRow}
        />
    )
}

export default ServicesTableBlockPagination;
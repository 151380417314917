import React, { useEffect } from "react";
import { Controller, FieldValues, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from 'dayjs';

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Checkbox,
  TimePicker,
  Typography,
} from "antd";
import { CalendarDay, EmployeeForm } from "./models/EmployeeForm";
import Wrp from "../../components/layout/Wrp/Wrp";
import { employeeRoleOptions } from "./models/EmployeeRoleOptions";
import { useGetEmployeeQuery, useSendPasswordToAdminMutation, useUpdateEmployeeMutation } from "../../store/api/employees.api";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { shallowEqual } from "react-redux";
import { validationSchema } from "./validationSchema";
import { calendarEventDayOptions } from "../../common/constants/CalendarEventDayOptions";
import { EmployeeRole } from "./models/EmployeeRole";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const Employee = () => {
  const { id } = useParams();
  const employeeId = Number(id);
  const vendorId =
    useAppSelector((state) => state.user.vendorId, shallowEqual) || -1;
  const { data } = useGetEmployeeQuery({
    vendorId,
    employeeId,
  });

  const [updateEmployee] = useUpdateEmployeeMutation();
  const[sendPasswordToAdmin] =  useSendPasswordToAdminMutation()

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
    setValue,
  } = useForm<EmployeeForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: data || {}
  });

  useEffect(() => {
    if (data) {
      const transformedData = {
        ...data,
        calendar: Object.keys(data.calendar || {}).reduce((acc: { [key: number]: CalendarDay }, key: string) => {
          const numericKey = parseInt(key, 10);
          acc[numericKey] = {
            checked: !!data.calendar[numericKey].timeRanges,
            timeRanges: {
              from: data.calendar[numericKey].timeRanges?.from ? dayjs(data.calendar[numericKey].timeRanges.from).format("HH:mm") : '',
              to: data.calendar[numericKey].timeRanges?.to ? dayjs(data.calendar[numericKey].timeRanges.to).format("HH:mm") : '',
            },
          };
          return acc;
        }, {}),
      };
      reset(transformedData);
    }
  }, [data, reset]);

  const onSubmit = (formData: EmployeeForm) => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    const transformedCalendar = Object.entries(formData.calendar || {})
    .filter(([, value]) => value.checked)
    .reduce((acc: { [key: number]: CalendarDay }, [key, value]) => {
      const numericKey = parseInt(key, 10);
      acc[numericKey] = {
        checked: value.checked,
        timeRanges: {
          from: value.timeRanges?.from ? dayjs(value.timeRanges.from, "HH:mm").toISOString() : null,
          to: value.timeRanges?.to ? dayjs(value.timeRanges.to, "HH:mm").toISOString() : null,
        },
      };
      return acc;
    }, {});

    const transformedData = { ...formData, calendar: transformedCalendar } as EmployeeForm;

    updateEmployee({ vendorId, employeeId, data: transformedData});
  };

  const selectedDays = useWatch({
    control,
    name: "calendar",
  });
  const watchRole = useWatch({control, name:"roleId"});

  const isAdmin = String(watchRole) === EmployeeRole.Admin

  const onChangeDay = (field: FieldValues) => (e: CheckboxChangeEvent) => {
    const name = e.target.name || '';
    const match = name.match(/\d+/);
    if (match) {
      const index = parseInt(match[0], 10);
      if (!e.target.checked) {
        setValue(`calendar.${index}.timeRanges.from` as keyof EmployeeForm, '');
        setValue(`calendar.${index}.timeRanges.to` as keyof EmployeeForm, '');
      }
      field.onChange(e.target.checked);
    }
  };

  const handleSendPasswordToAdmin=()=>{
    sendPasswordToAdmin({vendorId, employeeId})
  }

  const safeParseDate = (value: any, format: string) => {
    if (typeof value === 'string' || typeof value === 'number' || value === null || value === undefined) {
      return dayjs(value, format);
    }
    return null;
  };

  return (
    <Wrp flexDirection="column">
      <Form
        onFinish={handleSubmit(onSubmit)}
        style={{ width: "100%" }}
        layout="vertical"
      >
        <Row gutter={45} align="middle">
          <Col span={12}>
            <Form.Item
              label="Прізвище та ім'я працівника"
              validateStatus={errors.fullname ? "error" : ""}
              help={errors.fullname && errors.fullname.message}
            >
              <Controller
                control={control}
                name="fullname"
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Col>
          <Col span={isAdmin? 6: 12}>
            <Form.Item
              label="Посада"
              validateStatus={errors.roleId ? "error" : ""}
              help={errors.roleId && errors.roleId.message}
            >
              <Controller
                name="roleId"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    filterOption={false}
                    options={employeeRoleOptions}
                  />
                )}
              />
            </Form.Item>
            </Col>
            {isAdmin && (<Col span={6}><Button type="primary" onClick={handleSendPasswordToAdmin}>Надіслати пароль</Button></Col>)}
        </Row>

        <Row gutter={45}>
          <Col span={12}>
            <Form.Item
              label="Телефон"
              validateStatus={errors.phone ? "error" : ""}
              help={errors.phone && errors.phone.message}
            >
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <Input {...field} style={{ width: "100%" }} />
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Email"
              validateStatus={errors.email ? "error" : ""}
              help={errors.email && errors.email.message}
            >
              <Controller
                control={control}
                name="email"
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Typography.Title level={4} style={{ marginBottom: 30 }}>
          Робочі дні:
        </Typography.Title>
        {calendarEventDayOptions.map((day) => {
          const isChecked = selectedDays && selectedDays[day.value]?.checked;

          return (
            <Row gutter={45} key={day.value}>
              <Col span={2}>
                <Form.Item>
                  <Controller
                    name={`calendar.${day.value}.checked` as keyof EmployeeForm}
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={Boolean(field.value)}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          onChangeDay(field)(e);
                        }}
                      >
                        {day.label}
                      </Checkbox>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  validateStatus={
                    errors?.calendar?.[day.value]?.timeRanges?.from ? "error" : ""
                  }
                  help={
                    errors?.calendar?.[day.value]?.timeRanges?.from?.message || ""
                  }
                >
                  <Controller
                    control={control}
                    name={`calendar.${Number(day.value)}.timeRanges.from` as keyof EmployeeForm}
                    render={({ field }) => (
                      <TimePicker
                        {...field}
                        value={field.value ? safeParseDate(field.value, "HH:mm") : null}
                        format="HH:mm"
                        placeholder="Від"
                        disabled={!isChecked}
                        onChange={(time, timeString) => field.onChange(timeString)}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  validateStatus={
                    errors?.calendar?.[day.value]?.timeRanges?.to ? "error" : ""
                  }
                  help={
                    errors?.calendar?.[day.value]?.timeRanges?.to?.message || ""
                  }
                >
                  <Controller
                    control={control}
                    name={`calendar.${Number(day.value)}.timeRanges.to` as keyof EmployeeForm}
                    render={({ field }) => (
                      <TimePicker
                        {...field}
                        value={field.value ? safeParseDate(field.value, "HH:mm") : null}
                        format="HH:mm"
                        placeholder="До"
                        disabled={!isChecked}
                        onChange={(time, timeString) => field.onChange(timeString)}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        })}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!isDirty || !isValid}
          >
            Зберегти
          </Button>
        </Form.Item>
      </Form>
    </Wrp>
  );
};

export default Employee;
import React from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { MoneyValues } from "./models/MoneyValues";
import { updateCategoriesFetch } from "../../../store/state/money/money.slice";
import { useFormik } from "formik";
import validationSchema from "./Money.scheme";
import { Filter } from "../../../common/models/Filters";
import moment from "moment";
import MoneyMessage from "./Money.message";

const MoneyContainer = () => {
    const dispatch = useAppDispatch();

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const discounts = useAppSelector(state => state.money.discounts, shallowEqual);
    const operations = useAppSelector(state => state.money.operations, shallowEqual);
    const purses = useAppSelector(state => state.money.purses, shallowEqual);

    const operationsIncome = operations.filter(operation => operation.income);
    const operationsExpense = operations.filter(operation => !operation.income);

    const initialValues: MoneyValues = {
        discounts,
        operationsIncome,
        operationsExpense,
        purses,
    }

    const formik = useFormik<MoneyValues>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const { operationsExpense, operationsIncome, discounts, purses } = values;
            const operations = [...operationsIncome, ...operationsExpense];

            const today = new Date();
            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const filter: Filter = {
                period: {
                    from: moment(firstDay).format("YYYY-MM-DD"),
                    to: moment(lastDay).format("YYYY-MM-DD"),
                }
            }

            dispatch(updateCategoriesFetch({ vendorId: vendorId || -1, discounts, purses, operations, filter }));
        },
    });

    return (
        <MoneyMessage
            formik={formik}
        />
    )
}

export default React.memo(MoneyContainer);
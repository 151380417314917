import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Money } from "../../../../services/reddyApi/Money/Money";
import { NEGATIVE_MEANING } from "../../../../common/constants/operationsWithNumbers";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { createTransactionSuccess } from "../../clients/clients.slice";
import { getStudentDataForGroupFetch } from "../../groups/groups.slice";
import getNotifications from "../../notifications/saga/getNotifications";
import {
    getStatisticsClient,
    getStatisticsDashboard,
    getStatisticsFinance,
    getStatisticsGroup
} from "../../statistics/statistics.saga";

import { createMoneyTransactionFetch, createMoneyTransactionSuccess } from "../finance.slice";
import { CreateMoneyTransactionPayload } from "../payload/CreateMoneyTransaction/CreateMoneyTransactionPayload";

function* createMoneyTransactionWorker(action: PayloadAction<CreateMoneyTransactionPayload>) {
    const { vendorId, transaction, clientId, filter } = action.payload;
    const { counteragent, responsibleUser, date, sum, purseId, operationId, groupId, studentId, comment, scheduled, income } = transaction;

    try {
        const { data } = yield call(Money.createMoneyTransaction, {
            vendorId, transaction: {
                counteragent,
                date,
                sum,
                purseId,
                operationId,
                groupId,
                studentId,
                comment,
                income,
                scheduled,
            }
        });

        const { transactionId } = data
        const transaction = {
            id: transactionId,
            counteragent,
            purseId,
            operationId,
            sum: income ? sum : sum * NEGATIVE_MEANING,
            comment,
            income,
            groupId,
            responsibleUser,
            createdAt: date,
            scheduled
        }

        yield put(createMoneyTransactionSuccess(transaction));

        if (clientId !== undefined) {
            yield put(createTransactionSuccess({
                transaction,
                clientId
            }));

            yield getStatisticsClient({ vendorId, clientId });
        }

        yield getStatisticsDashboard({ vendorId, filter });

        yield getStatisticsFinance({ vendorId, filter });

        yield getStatisticsGroup({ vendorId, groupId: groupId || -1 });

        yield put(getStudentDataForGroupFetch({ vendorId, groupId: groupId || -1, studentId: studentId || -1 }));

        yield getNotifications(vendorId);

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => createMoneyTransactionFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default createMoneyTransactionWorker;
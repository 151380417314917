import { FormikProps, FormikProvider } from "formik";
import { FC } from "react";
import Field from "../../../../components/dataEntry/inputs/Field/Field";
import Gallery from "../../../../components/dataEntry/inputs/Gallery/Gallery";
import Switch from "../../../../components/dataEntry/inputs/Switch/Switch";
import Textarea from "../../../../components/dataEntry/inputs/Textarea/Textarea";
import Button from "../../../../components/general/buttons/Button/Button";
import Column from "../../../../components/layout/Column/Column";
import Row from "../../../../components/layout/Row/Row";
import Wrp from "../../../../components/layout/Wrp/Wrp";
import { BasicValues } from "../models/BasicValues";
import CategoryDropdown from "./components/CategoryDropdown/CategoryDropdown";
import DurationTypeDropdown from "./components/DurationTypeDropdown/DurationTypeDropdown";
import LocationsIdsTagInput from "./components/LocationsTagInput/LocationsTagInput";
import TypeDropdown from "./components/TypeDropdown/TypeDropdown";
import UpsellsTagInput from "./components/UpsellsTagInput/UpsellsTagInput";
import { SUBMIT_ACTIONS } from "../../../../common/constants/submitActions";
import DraggableFieldsBlock from "../../../../components/dataEntry/inputs/DraggableFieldsBlock/DraggableFieldsBlock";

interface Props {
    isNewItemForm?: boolean;
    formik: FormikProps<BasicValues>;
    active?: boolean;
}

const Basic: FC<Props> = ({ formik, isNewItemForm, active }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = formik;

    const sendButtonSubmitHandler = () => {
        setFieldValue("submitAction", SUBMIT_ACTIONS.SAVE);
        handleSubmit();
    }

    const archiveButtonSubmitHandler = () => {
        setFieldValue("submitAction", SUBMIT_ACTIONS.DELETE);
        handleSubmit();
    }

    const upsellsIds = values.upsellsIds

    const changeLessonsHandler = (value: string) => {
        setFieldValue("lessons", value);
    }

    const typeId = Number(values.typeId);

    const durationTypeId = Number(values.durationTypeId);

    const categoryId = Number(values.categoryId);

    const locationsIds = values.locationsIds;

    const images = values.images;
    const files = values.files;
    const links = values.links;

    let gallery: (string | undefined)[] = [undefined, undefined, undefined];
    gallery = gallery.map((image, index) => (images && images[index]) || undefined);

    let filesGallery: (File | undefined)[] = [undefined, undefined, undefined];
    filesGallery = filesGallery.map((file, index) => (files && files[index]) || undefined);

    const linksGalley = links?.map((link, index) => (links && links[index]) || undefined) || [];

    return (
        <FormikProvider value={formik}>
            <Wrp maxWidth={1160}>
                <Column width="50%">
                    <Field
                        label="Назва послуги"
                        labelLeftShift={0}
                        maxWidth={550}
                        marginRight={20}
                        marginBottom={20}
                        handleChange={handleChange("name")}
                        handleBlur={handleBlur("name")}
                        value={values.name}
                        error={errors.name}
                        touched={touched.name}
                    />

                    <Row alignItems="flex-end" marginBottom={20}>
                        <UpsellsTagInput
                            upsellsIdsValues={upsellsIds}
                            error={errors.upsellsIds}
                            touched={touched.upsellsIds}
                            handleBlur={handleBlur("upsellsIds")}
                            setFieldValue={setFieldValue}
                        />
                    </Row>

                    <Textarea
                        title="Опис"
                        maxWidth={550}
                        maxHeight={200}
                        marginBottom={20}
                        handleChange={handleChange("description")}
                        handleBlur={handleBlur("description")}
                        value={values.description}
                        error={errors.description}
                        touched={touched.description}
                    />

                    <DraggableFieldsBlock
                        titles={["К-ть занять", "Назва заняття"]}
                        handleChange={changeLessonsHandler}
                        handleBlur={handleBlur("lessons")}
                        errors={errors.lessons}
                        touched={touched.lessons}
                        values={values.lessons}
                    />
                </Column>
                <Column width="50%" justifyContent="flex-start" alignItems="flex-start">
                    <Row alignItems="flex-end" marginBottom={20}>
                        <TypeDropdown
                            typeId={typeId}
                            error={errors.typeId}
                            touched={touched.typeId}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                        />

                        <DurationTypeDropdown
                            durationTypeId={durationTypeId}
                            error={errors.typeId}
                            touched={touched.typeId}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                        />

                        <Field
                            type="number"
                            maxWidth={80}
                            marginRight={30}
                            marginBottom={0}
                            handleChange={handleChange("duration")}
                            value={values.duration}
                            error={errors.duration}
                            touched={touched.duration}
                            step={0.25}
                        />
                        <Switch
                            value={values.confirmed}
                            title="Статус"
                            handleChange={handleChange("confirmed")}
                        />
                    </Row>
                    <Row alignItems="flex-end" marginBottom={20}>
                        <CategoryDropdown
                            categoryId={categoryId}
                            error={errors.categoryId}
                            touched={touched.categoryId}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                        />
                    </Row>
                    <Row alignItems="flex-end" marginBottom={20}>
                        <LocationsIdsTagInput
                            locationsIdsValues={locationsIds}
                            error={errors.locationsIds}
                            touched={touched.locationsIds}
                            handleBlur={handleBlur("locationsIds")}
                            setFieldValue={setFieldValue}
                        />
                    </Row>
                    <>
                        {!isNewItemForm &&
                            < Gallery
                                imgs={gallery}
                                acceptFile=".PNG,.JPG"
                                setFieldValue={setFieldValue}
                                files={filesGallery}
                                links={linksGalley}
                            />

                        }
                    </>
                </Column>
            </Wrp>
            <Wrp maxWidth={1060}>
                <Row>
                    <Button
                        disabled={!isValid}
                        backgroundColor="#4775FE"
                        title="Зберегти"
                        handleClick={sendButtonSubmitHandler}
                        marginRight={20}
                    />
                    <>
                        {!isNewItemForm &&
                            <Button
                                backgroundColor="#A6AEC5"
                                title={active ? "Архівувати" : "Активувати"}
                                handleClick={archiveButtonSubmitHandler}
                            />
                        }
                    </>
                </Row>
            </Wrp>
        </FormikProvider>
    )

}

export default Basic;
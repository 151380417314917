import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { setFonVisiable } from "../../../../store/state/all/all.slice";
import { setVisiableTransactionForm } from "../../../../store/state/clients/clients.slice";
import { createMoneyTransactionFetch, setDefaultValueFinance } from "../../../../store/state/finance/finance.slice";
import { Counteragent } from "../../../../store/state/finance/models/Counteragent";
import { Transaction } from "../../../../store/state/finance/models/Transaction";
import { Filter } from "../../../../common/models/Filters";
import { TransactionFormValue } from "../../models/TransactionFormValue";
import AddTransactionForm from "./AddTransactionForm";
import validationSchema from "./AddTransactionForm.scheme"

const AddTransactionFormContainer = () => {
    let { id } = useParams();
    const clientId = (id && parseInt(id)) || -1;
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const dispatch = useAppDispatch();

    let clients = useAppSelector(state => state.clients.pageData, shallowEqual);
    let indexClient = clients.findIndex(client => client.clientId === clientId);
    let users = clients[indexClient].users;
    let students = clients[indexClient].students.map(student => ({
        id: student.id,
        fullname: `${student.surname} ${student.name}`,
        groups: student.groups.map(group => ({
            id: group.id,
            name: group.name,
            serviceName: group.serviceName,
        }))
    }));

    const visiable = useAppSelector(state => state.clients.visiableTransactionForm, shallowEqual);
    const responsibleUserId = useAppSelector(state => state.user.id, shallowEqual) || -1;
    const fullname = useAppSelector(state => state.user.name, shallowEqual);
    const roleId = useAppSelector(state => state.user.roleId, shallowEqual) || -1;

    const initialValues: TransactionFormValue = {
        userId: users[0].id,
        sum: 0,
        date: "",
        operationId: -1,
        purseId: -1,
        groupId: -1,
        comment: "",
        scheduled: false,
        studentId: students[0].id,
    }

    const formik = useFormik<TransactionFormValue>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const { userId, groupId, date, sum, purseId, operationId, comment, scheduled, studentId } = values;
            const responsibleUser = {
                id: responsibleUserId,
                fullname: fullname,
                roleId: roleId,
            }

            const indexUser = users.findIndex(user => user.id === userId);
            const userItem = users[indexUser];

            const counteragent: Counteragent = {
                userId,
                name: userItem.name + " " + userItem.surname,
            }

            const transaction: Transaction = {
                counteragent,
                groupId,
                date,
                responsibleUser,
                sum,
                purseId,
                operationId,
                comment,
                income: true,
                studentId,
                scheduled,
            };

            const today = new Date();
            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const filter: Filter = {
                period: {
                    from: moment(firstDay).format("YYYY-MM-DD"),
                    to: moment(lastDay).format("YYYY-MM-DD"),
                }
            }

            dispatch(createMoneyTransactionFetch({ transaction, vendorId, clientId, filter }));
            closeModalHandler();
        },
    });

    const closeModalHandler = () => {
        formik.resetForm();
        dispatch(setVisiableTransactionForm({ visiable: false }));
        dispatch(setFonVisiable({ visiable: false }));
        dispatch(setDefaultValueFinance({ counteragents: true }));
    }

    return (
        <AddTransactionForm
            users={users}
            students={students}
            visiable={visiable}
            closeModalHandler={closeModalHandler}
            formik={formik}
        />
    );
}

export default React.memo(AddTransactionFormContainer);
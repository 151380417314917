import moment from "moment";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import PriceView from "../../../../components/dataDisplay/table/views/PriceView/PriceView";
import ButtonInfo from "../../../../components/general/buttons/ButtonInfo/ButtonInfo";
import IconFont from "../../../../components/general/icons/IconFont/IconFont";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { MoneyAdapter } from "../../../../common/adapters/MoneyAdapter";
import { FinanceColumn } from "../../models/FinanceColumn";
import DataTableBlockPagination from "./DataTableBlock.pagination";

const DataTableBlockContainer: FC = () => {
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const transactions = useAppSelector(state => state.finance.finance.transactions, shallowEqual);
    const purses = useAppSelector(state => state.money.purses, shallowEqual);
    const operations = useAppSelector(state => state.money.operations, shallowEqual);

    const data: FinanceColumn[] = transactions.map(item => {
        const category = MoneyAdapter(operations, item.operationId);

        return ({
            datetime: moment(item.createdAt).format("DD.MM.YYYY"),
            sum: <PriceView price={item.sum} income={item.income} exchange={category === 0} />,
            score: `${MoneyAdapter(purses, Number(item.purseId)) || "-"}`,
            counterparty: item.counteragent?.name || "-",
            category: `${category === 0 ? "Переказ" : category || "-"}`,
            responsible: item.responsibleUser?.fullname || "-",
            comment: <ButtonInfo icon={<IconFont color="#A6AEC5" iconClass="icon-messageicon" fontSize={15} />} info={item.comment || "Коментар не додан"} />
        })
    });

    return (
        <DataTableBlockPagination
            data={data}
            vendorId={vendorId}
        />
    )
}

export default DataTableBlockContainer;
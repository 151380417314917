import React, { MouseEvent } from "react";
import { shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonIcon from "../../components/general/buttons/ButtonIcon/ButtonIcon";
import ButtonInfo from "../../components/general/buttons/ButtonInfo/ButtonInfo";
import Avatar from "../../components/dataDisplay/user/Avatar/Avatar";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { EmployeesColumns } from "./models/EmployeesColumns";
import { configAdapter } from "../../common/adapters/ConfigAdapter";
import IconFont from "../../components/general/icons/IconFont/IconFont";
import withMatch from "../../components/other/hoc/withMatch";
import LocationView from "../../components/dataDisplay/LocationView/LocationView";
import { locationNameAdapter } from "../../common/adapters/LocationNameAdapter";
import EmployeesPagination from "./Employess.pagination";
import StatusView from "../../components/dataDisplay/table/views/StatusView/StatusView";

const EmployeesConatiner = () => {
    let navigation = useNavigate();

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const employees = useAppSelector(state => state.employees.employeesArray, shallowEqual);
    const roles = useAppSelector(state => state.config.userConfig?.user.roles, shallowEqual);
    const locations = useAppSelector(state => state.locations.locationsArray, shallowEqual);

    const data: EmployeesColumns[] = employees.map(employee => {
        const locationsIds = employee.locationsIds;
        const location = locationsIds && locationsIds.length > 1 ?
            <LocationView
                count={locationsIds.length}
                link="settings/locations"
                locations={locationsIds.map(locationId => ({ id: locationId, name: locationNameAdapter(locations, locationId) }))}
            /> : locationNameAdapter(locations, locationsIds[0]);

        const active = employee?.active !== undefined ? employee?.active : true;
        const status = active ? 1 : 2;

        return {
            id: employee.id,
            avatar:
                <Avatar
                    name={employee.fullname}
                    width={50}
                    height={50}
                    background="#A6AEC5"
                    backgroundName="#A6AEC5"
                    fontSize={18}
                    lineHeight={21}
                    maxHeightImg={"100%"}
                    maxWidthImg={"100%"}
                />,
            name: employee.fullname,
            role: roles ? configAdapter(roles, employee.roleId) || "" : "",
            location: location,
            status:
                <StatusView
                    statusOff="Не активний"
                    statusOn="Активний"
                    status={status}
                />,
            link:
                <ButtonIcon
                    icon={<IconFont color="#A6AEC5" iconClass="icon-edit" fontSize={13} />}
                    handleClick={() => navigation(`${employee.id}`, {})}
                />,
            phone:
                <ButtonInfo
                    icon={<IconFont color="#A6AEC5" iconClass="icon-tel" fontSize={13} />}
                    info={employee.phone}
                />,
            email:
                <ButtonInfo
                    icon={<IconFont color="#A6AEC5" iconClass="icon-email" fontSize={13} />}
                    info={employee.email}
                />,
        }
    });

    const clickRowTableHandler = (item: any, event: MouseEvent<HTMLTableRowElement>) => {
        navigation(`${item.id}`, {})
    }

    return (
        <EmployeesPagination
            data={data}
            handleClickRow={clickRowTableHandler}
            vendorId={vendorId}
        />
    );
}

export default withMatch(React.memo(EmployeesConatiner), [2, 3, 4]);
import React from "react";
import { shallowEqual } from "react-redux";
import { Navigate } from "react-router-dom";
import withMatch from "../../components/other/hoc/withMatch";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { SYSTEM_ADMIN_ID } from "../../common/constants/role";
import Dashboard from "./Dashboard";

const DashboardContainer = () => {
    const services = useAppSelector(state => state.services.basics, shallowEqual);
    const isEmpty = services.length < 1;
    const userRoleId = useAppSelector(state => state.user.roleId, shallowEqual);
    
    if(userRoleId && userRoleId === SYSTEM_ADMIN_ID) {
        return (
            <Navigate to={"/vendors"} />
        );
    }

    return (
        <Dashboard isEmpty={isEmpty} />
    );
}

export default React.memo(withMatch(DashboardContainer, [1, 2, 3, 4]));
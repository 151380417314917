import React, { FC, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import withAuthCheckStatus from "../../components/other/hoc/withAuthCheckStatus";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { setLoadingData } from "../../store/state/all/all.slice";
import { downloadClientsTemplateFetch } from "../../store/state/clients/clients.slice";
import { getUserConfigFetch, getVendorConfigFetch } from "../../store/state/configuration/configuration.slice";
import { getCategoriesFetch } from "../../store/state/money/money.slice";
import Preloader from "./Preloader";
import withLoadingClients from "./withLoadingClients";
import withLoadingDashboard from "./withLoadingDashboard";
import withLoadingEmloyess from "./withLoadingEmployees";
import withLoadingFinance from "./withLoadingFinance";
import withLoadingGroups from "./withLoadingGroups";
import withLoadingLocations from "./withLoadingLocations";
import withLoadingNotifications from "./withLoadingNotifications";
import withLoadingIdElement from "./withLoadingOneElement";
import withLoadingServices from "./withLoadingServices";
import withLoadingUpsells from "./withLoadingUpsells";
import withLoadingVendors from "./withLoadingVendors";

const PreloaderContainer: FC = () => {
    const [percentage, setPercentage] = useState(0);
    const dispatch = useAppDispatch();
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    useEffect(() => {
        dispatch(getUserConfigFetch());
        dispatch(getVendorConfigFetch());
        dispatch(getCategoriesFetch({ vendorId }));
        dispatch(downloadClientsTemplateFetch());
    }, [vendorId]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setPercentage(prev => prev += 1);
        }, 20);

        if (percentage >= 100) {
            dispatch(setLoadingData({ status: true }));
        }

        return () => {
            clearTimeout(handler);
        }
    }, [percentage]);

    return (
        <Preloader
            percentage={percentage}
        />
    );
}

export default withAuthCheckStatus(withLoadingNotifications(withLoadingIdElement(withLoadingDashboard(withLoadingClients(withLoadingGroups(withLoadingVendors(withLoadingFinance(withLoadingUpsells(withLoadingLocations(withLoadingServices(withLoadingEmloyess(PreloaderContainer))))))))))));
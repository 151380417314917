import { PayloadAction } from "@reduxjs/toolkit";
import { Error } from "../../../../common/models/Error";
import { EmployeesState } from "../models/EmployeesState";

const setError = (state: EmployeesState, action: PayloadAction<Error>) => {
    state.isLoading = false;
    state.isError = action.payload.error;
    state.message = "Виникла помилка";
    state.messageVisiable = true;
}

export default setError;
import moment from "moment";
import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { getActiveServicesListFetch, getClientsListForVendorFetch } from "../../store/state/dashboard/dashboard.slice";
import { SYSTEM_ADMIN_ID } from "../../common/constants/role";
import { culcPage } from "../../common/helpers/culcPage";
import { Filter } from "../../common/models/Filters";

function withLoadingDashboard(Component: React.ComponentType) {
    const ComponentWithLoadingDashboard: FC = () => {
        const dispatch = useAppDispatch();
        
        const pageActiveServices = useAppSelector(state => state.dashdoard.activeSrvicePage.page, shallowEqual);
        const totalCountActiveServices = useAppSelector(state => state.dashdoard.activeSrvicePage.totalCount, shallowEqual);
        const pageAllActiveServices = useAppSelector(state => state.dashdoard.activeSrvicePage.pageAll, shallowEqual);
        const pageSizeActiveServices = useAppSelector(state => state.dashdoard.activeSrvicePage.pageSize, shallowEqual);
        const getAllItemsActiveServices = useAppSelector(state => state.dashdoard.activeSrvicePage.getAllItems, shallowEqual);
        const pagesRangeActiveServices = culcPage(pageActiveServices, pageAllActiveServices, totalCountActiveServices, pageSizeActiveServices);

        const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
        const roleId = useAppSelector(state => state.user.roleId, shallowEqual) || -1;

        useEffect(() => {
            if(roleId > SYSTEM_ADMIN_ID) {
                let filter: Filter = {
                    active: true,
                }
            
                if(!getAllItemsActiveServices) {
                    filter = {
                        range: {
                            from: pagesRangeActiveServices.from,
                            to: pagesRangeActiveServices.to
                        },
                    }  
                }

                dispatch(getActiveServicesListFetch({vendorId, filter}));
            }
        }, [vendorId, roleId]);

        return (
            <Component />
        );
    }

    return ComponentWithLoadingDashboard;
}

export default withLoadingDashboard;
import { EmployeeRole } from './EmployeeRole';

export const employeeRoleOptionsLabel = {
    [EmployeeRole.Admin]: "Адмін",
    [EmployeeRole.Teacher]: "Вчитель",
}

export const employeeRoleOptions = Object.values(EmployeeRole).map((role) => ({
    label: employeeRoleOptionsLabel[role],
    value: Number(role),
}))
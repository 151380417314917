import { AxiosError } from "axios";
import { put } from "redux-saga/effects";
import { SERVER_ERROR_500 } from "../../../../common/constants/app";
import { getUserDataFetch } from "../../user/user.slice";
import { setServerError } from "../all.slice";
import { Action } from "../../../../common/models/Action";

function* handleServerError(error: AxiosError, actionCreator?: () => Action) {
    const statusCode = error.response?.status;

    switch (statusCode) {
        case 400:
            yield put(setServerError({
                error: "Request not valid",
                status: statusCode,
                message: "Щось пішло не так, спробуйте перезавантажити сторінку, якщо це не допоможе напишіть в підтримку"
            }));
            break;
        case 401:
            yield put(getUserDataFetch({ actionCreator }));
            break;
        case 403:
            yield put(setServerError({
                error: "Forbidden",
                status: statusCode,
                message: "Щось пішло не так, спробуйте перезавантажити сторінку, якщо це не допоможе напишіть в підтримку"
            }));
            break;
        case 404:
            yield put(setServerError({
                error: "Not Found",
                status: statusCode,
                message: "Щось пішло не так, спробуйте перезавантажити сторінку, якщо це не допоможе напишіть в підтримку"
            }));
            break;
        default:
            yield put(setServerError({
                error: "Unknown error",
                status: SERVER_ERROR_500,
                message: "Щось пішло не так, спробуйте перезавантажити сторінку, якщо це не допоможе напишіть в підтримку"
            }));
    }
}

export default handleServerError;
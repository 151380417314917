import axios from "axios";
import Token from "../Token/token";
import { BASE_URL } from "../../common/constants/app";

const instance = axios.create({
    baseURL: BASE_URL,
    withCredentials: false,
    headers: { "Content-Type": "application/json", }
});

instance.interceptors.request.use(function (config) {
    // console.log("instance interceptors")
    // console.log({ config })
    if (config && config.headers) {
        let token = Token.getAccessToken();

        if (!token) {
            token = Token.getRefreshToken();
        }

        config.headers.Authorization = `Bearer ${token}`;
    }
    // console.log({ config })
    return config;
}, function (error) {
    return Promise.reject(error);
}
);

export default instance;
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { BASE_URL } from '../../common/constants/app'
import { prepareHeaders } from '../../common/helpers/prepareHeaders';


export const baseQuery = fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders,
})


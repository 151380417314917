import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import { getClientMoneyTransactionsByVendorFetch, setPageClientsTransactions } from "../../../../../store/state/clients/clients.slice";
import culcPageTable from "../../../../../common/helpers/culcPageTable";
import usePagination from "../../../../../common/hooks/usePagination";
import { Filter } from "../../../../../common/models/Filters";
import { TransactionsColumn } from "../../../models/TransactionsColumn";
import useGetClientMoneyTransactionsByVendor from "../../hook/useGetClientMoneyTransactionsByVendor";
import Transactions from "./Transactions";

interface Props {
    data: TransactionsColumn[];
    clientId: number;
    vendorId: number;
}

const TransactionsPagination: FC<Props> = ({ clientId, vendorId, data }) => {
    const dispatch = useAppDispatch();

    const page = useAppSelector(state => state.clients.transactionsPages.page, shallowEqual);
    const totalCount = useAppSelector(state => state.clients.transactionsPages.totalCount, shallowEqual);
    const pageAll = useAppSelector(state => state.clients.transactionsPages.pageAll, shallowEqual);
    const pageSize = useAppSelector(state => state.clients.transactionsPages.pageSize, shallowEqual);
    const getAllItems = useAppSelector(state => state.clients.transactionsPages.getAllItems, shallowEqual);

    const setPage = (filter: Filter | undefined, pageNumber: number) => {
        dispatch(getClientMoneyTransactionsByVendorFetch({ vendorId, filter, clientId }));
        dispatch(setPageClientsTransactions({ page: pageNumber }));
    };

    const pagination = usePagination({
        getAllItems,
        pageAll,
        page,
        totalCount,
        pageSize,
        setPage,
    });

    const pagesRange = pagination.pagesRange;

    useGetClientMoneyTransactionsByVendor({ 
        getAllItems, 
        from: pagesRange.from, 
        to: pagesRange.to, 
        vendorId, 
        clientId 
    });

    const pageTable = culcPageTable<TransactionsColumn>(data, pagesRange.from, pagesRange.to);

    return (
        <Transactions
            data={pageTable}
            page={page}
            pages={pagination.pages}
            handleLeftButtonPagination={pagination.handleLeftButtonPagination}
            handleRightButtonPagination={pagination.handleRightButtonPagination}
        />
    );
}

export default TransactionsPagination;
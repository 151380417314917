import * as Yup from 'yup';
import { DateApi } from '../../../../services/Date/Date';
import { TODAY } from '../../../../common/constants/date';

export default Yup.object().shape({
    counteragent: Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().required('Required'),
        userId: Yup.number().nullable(),
    }).required('Required'),
    sum: Yup.number()
        .min(1, "invalid value")
        .required("Required"),
    date: Yup.date()
        .transform(DateApi.parseDateString)
        .min(TODAY)
        .required("Заповніть це поле"),
    purseId: Yup.number().required("Required"),
    operationId: Yup.number().required("Required"),
    groupId: Yup.number().min(0, "Invalid Value").required('Required'),
    studentId: Yup.number().min(0, "Invalid Value").required("Required"),
    comment: Yup.string()
});
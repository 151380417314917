import React, { FC, FocusEvent, useEffect, useState } from "react";
import { PagePagination } from "../../../dataDisplay/table/models/PagePagination";
import Wrp from "../../../layout/Wrp/Wrp";
import DraggableField from "../DraggableFields/DraggableFields";
import { Value } from "../DraggableFields/models/Value";
import Field from "../Field/Field";

interface Props {
    titles: [string, string];
    values: string;
    handleChange?: (value: string) => void;
    handleBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    errors?: string | undefined;
    touched?: boolean | undefined;
}

const DraggableFieldsBlock: FC<Props> = ({ values, titles, handleChange, handleBlur, errors, touched }) => {
    const [fields, setFields] = useState<Value[]>(
        values.split("[/]").map((item, index) => ({ id: index, value: item, order: index }))
    );
    const [valueCount, setValueCount] = useState(fields.length);
    const [page, setPage] = useState(1);

    const addField = (value: number) => {
        setFields(prev => {
            let i = valueCount;
            const newFields = prev.map(item => ({ ...item }));
            while (value > i) {
                const item: Value = { id: i, value: "Заняття " + (i + 1), order: i };
                newFields.push(item);
                i++;
            }

            return newFields;
        })

    }

    const removeField = (value: number) => {
        setFields(prev => {
            let i = valueCount;
            let newFields = prev.map(item => ({ ...item }));
            while (value < i && i > 0) {
                i--;
                newFields = newFields.filter(item => item.order !== i);
            }

            return newFields;
        });
    }

    useEffect(() => {
        setFields(values.split("[/]").map((item, index) => ({ id: index, value: item, order: index })));
    }, [values]);

    const pages: PagePagination[] = [];

    const pageSize = 25;
    const totalCount = fields.length;
    const pageAll = Math.ceil(totalCount / pageSize);

    for (let i = 0; i < pageAll; i++) {
        const pageNumber = i + 1;
        const handleClick = () => {
            setPage(pageNumber);
        }

        pages.push({
            name: pageNumber,
            handleClick,
        });
    }

    const handleRightButtonPagination = () => {
        setPage(prevPage => prevPage + 1);
    }

    const handleLeftButtonPagination = () => {
        setPage(prevPage => prevPage - 1);
    }

    return (
        <Wrp
            marginBottom={30}
            alignItems="flex-start"
        >
            <Field
                type="number"
                label={titles[0]}
                labelLeftShift={0}
                maxWidth={80}
                marginRight={30}
                handleChange={(event) => {
                    const value = Number(event.target.value);


                    if (valueCount > value) {
                        removeField(value);
                    } else if (value > valueCount) {
                        addField(value);
                    }

                    setValueCount(value)
                }}
                value={valueCount}
            />

            <DraggableField
                title={titles[1]}
                values={fields}
                handleBlur={handleBlur}
                handleChange={handleChange}
                error={errors}
                touched={touched}
                paginated={true}
                paginationKey="DRAGGABLE_FIELD_"
                page={page}
                pages={pages}
                handleRightButtonPagination={handleRightButtonPagination}
                handleLeftButtonPagination={handleLeftButtonPagination}
                pageAll={pageAll}
                pageSize={pageSize}
                totalCount={totalCount}
            />
        </Wrp>
    );
}

export default React.memo(DraggableFieldsBlock);
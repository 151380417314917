import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Dropdown from "../../../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { useAppSelector } from "../../../../../../../../store/hooks/selector.hook";
import { configListAdapter } from "../../../../../../../../common/adapters/ConfigListAdapter";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../../../common/constants/defaultValue";

interface Props {
    relationshipStatus: number;
    error: string | undefined;
    touched: boolean | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void
}

const RelationshipStatusesDropdown: FC<Props> = ({ relationshipStatus, error, touched, setFieldValue, setFieldTouched }) => {
    const relationshipStatuses = useAppSelector(state => state.config.userConfig?.user.relationshipStatuses, shallowEqual);

    const relationshipStatusesArray = configListAdapter(relationshipStatuses);
    const indexRelationshipStatuses = relationshipStatusesArray?.findIndex(
        relationshipStatuse => relationshipStatuse.id === relationshipStatus
    );
    const defaultRelationshipStatuses = relationshipStatusesArray.length > 0 && relationshipStatusesArray[indexRelationshipStatuses];
    const defaultValueRelationshipStatuses: DefaultValue = {
        valueInput: (defaultRelationshipStatuses && String(defaultRelationshipStatuses.id)) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: defaultRelationshipStatuses && defaultRelationshipStatuses.name || DEFAULT_VALUES_DROPDOWN.valueText,
    }
    const relationshipStatuseItems = relationshipStatusesArray.map((relationshipStatuse, index) => (
        <DropdownItem
            key={"SHORT_SERVICE_ITEM_" + index}
            item={relationshipStatuse.name}
            valueInput={String(relationshipStatuse.id)}
            valueText={relationshipStatuse.name}
        />
    ));

    const changeHandler = (relationshipStatus: string) => {
        setFieldValue("relationshipStatus", parseInt(relationshipStatus));
    }

    const blurHandler = (touched: boolean) => {
        setFieldTouched("relationshipStatus", touched)
    }

    return (
        <Dropdown
            label="Спорідненість"
            maxWidth={240}
            height={40}
            marginRight={20}
            defaultValue={defaultValueRelationshipStatuses}
            doubleClickDropdown={false}
            error={error}
            touched={touched}
            handleBlur={blurHandler}
            handleChange={changeHandler}
        >
            {relationshipStatuseItems}
        </Dropdown>
    )
}

export default RelationshipStatusesDropdown;
import { createSlice } from '@reduxjs/toolkit';
import { NotificationsState } from './models/NotificationsState';
import getNotificationsFetchReducer from './reducer/getNotifications/getNotificationsFetch';
import getNotificationsSuccessReducer from './reducer/getNotifications/getNotificationsSuccess';
import changeNotificationsStatusFetchReducer from './reducer/changeNotificationsStatus/changeNotificationsStatusFetch';
import changeNotificationsStatusSuccessReducer from './reducer/changeNotificationsStatus/changeNotificationsStatusSuccess';
import setErrorReducer from './reducer/setError';
import resetNotificationsReducer from './reducer/resetNotifications';
import { Filter } from '../../../common/models/Filters';
import setNotificationsReducer from './reducer/setNotifications';
import setNotificationsFilterReducer from './reducer/setNotificationsFilter';

const filter: Filter = {
    typeId: undefined,
    statusId: undefined,
}

export const initialState: NotificationsState = {
    active: [],
    archive: [],
    selected: [],
    filter,
    isLoading: false,
    isError: null
}

export enum NotificationStatuses {
    ACTIVE = 1,
    SELECTED = 2,
    ARCHIVE = 3,
}

export const AdditionalSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        getNotificationsFetch: getNotificationsFetchReducer,
        getNotificationsSuccess: getNotificationsSuccessReducer,
        changeNotificationsStatusFetch: changeNotificationsStatusFetchReducer,
        changeNotificationsStatusSuccess: changeNotificationsStatusSuccessReducer,
        setError: setErrorReducer,
        resetNotifications: resetNotificationsReducer,
        setNotifications: setNotificationsReducer,
        setNotificationsFilter: setNotificationsFilterReducer,
    },
});

export const {
    getNotificationsFetch,
    getNotificationsSuccess,
    changeNotificationsStatusFetch,
    changeNotificationsStatusSuccess,
    setError,
    resetNotifications,
    setNotifications,
    setNotificationsFilter,
} = AdditionalSlice.actions

export default AdditionalSlice.reducer
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import DataBlock from "../../../../../components/dataDisplay/DataBlock/DataBlock";
import DataItem from "../../../../../components/dataDisplay/user/DataList/DataItem";
import DataList from "../../../../../components/dataDisplay/user/DataList/DataList";
import DataUser from "../../../../../components/dataDisplay/user/DataUser/DataUser";
import Gallery from "../../../../../components/dataEntry/inputs/Gallery/Gallery";
import Title from "../../../../../components/general/typography/Title/Title";
import Wrp from "../../../../../components/layout/Wrp/Wrp";
import { DateApi } from "../../../../../services/Date/Date";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import { Student } from "../../../../../store/state/clients/models/Student";
import { User } from "../../../../../store/state/clients/models/User";
import { configAdapter } from "../../../../../common/adapters/ConfigAdapter";
import { getRelationship } from "../../../../../common/helpers/getRelationship";

interface Props {
    users: User[];
    students: Student[];
    documents: string[];
}

const UserDataBlock: FC<Props> = ({ users, students, documents }) => {
    const relationshipStatuses = useAppSelector(state => state.config.userConfig?.user.relationshipStatuses, shallowEqual);
    const dataUsers = users.map((user, index) => {
        const relationship = getRelationship(relationshipStatuses, user.relationshipStatus);
        return (
            <DataUser
                key={"USER_DATA_" + user.id}
                name={`${user.surname} ${user.name}`}
                belonging={relationship}
                emails={[user.email]}
                phones={[user.phone]}
                marginBottom={index === users.length - 1 ? 0 : 25}
            />
        )
    });

    const studentsList = students.map((student, index) => {
        const active = student.groups.filter(group => group.active).map((group, index) => {
            const datetime = DateApi.parseDateTime(group.startDate);
            const date = DateApi.parseDateStringPrint(datetime[0]);

            return (
                <DataItem
                    key={"STUDENT_DATA_ACTIVE_" + group.id}
                    status={group.active}
                    date={date}
                    groupName={group.name}
                    serviceName={group.serviceName}
                />
            )
        });
        const archive = student.groups.filter(group => !group.active).map((group, index) => {
            const datetime = DateApi.parseDateTime(group.endDate);
            const date = DateApi.parseDateStringPrint(datetime[0]);

            return (
                <DataItem
                    key={"STUDENT_DATA_ARCHIVE_" + group.id}
                    status={group.active}
                    date={date}
                    groupName={group.name}
                    serviceName={group.serviceName}
                />
            )
        });
        return (
            <DataList
                name={`${student.surname} ${student.name}`}
                active={active}
                archive={archive}
            />
        );
    });

    return (
        <DataBlock>
            <Wrp flexDirection="column" marginBottom={30}>
                <Title
                    level={2}
                    color={"#A6AEC5"}
                    fontWeight={"700"}
                    title={"Батьки"}
                    marginBottom={20}
                />

                <>{dataUsers}</>
            </Wrp>
            <Wrp flexDirection="column" marginBottom={30}>
                <Title
                    level={2}
                    color={"#A6AEC5"}
                    fontWeight={"700"}
                    title={"Учні"}
                    marginBottom={20}
                />
                <>{studentsList}</>
            </Wrp>
            {/* <Wrp flexDirection="column" marginBottom={30} marginTop="auto">
                <Title 
                    level={2} 
                    color={"#A6AEC5"} 
                    fontWeight={"700"} 
                    title={"Документи"} 
                    marginBottom={20}
                />
                <Gallery 
                    disabled={false}
                    titleVisiable={false}
                    imgs={[documents[0], documents[1], documents[2]]} 
                    firstSize={70}
                    allSize={70} 
                    borderRadius={15}
                    iconSmall={true}
                />
            </Wrp> */}
        </DataBlock>
    );
}

export default UserDataBlock;
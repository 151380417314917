import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Message from "../../components/feedback/messages/Message/Message";
import withMatch from "../../components/other/hoc/withMatch";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { setMessageVisiable } from "../../store/state/finance/finance.slice";
import { useMessageActions } from "../../common/hooks/useMessageActions";
import Finance from "./Finance";

const FinanceContainer: FC = () => {
    const dispatch = useAppDispatch();
    
    const message = useAppSelector(state => state.finance.finance.message, shallowEqual);
    const visiable = useAppSelector(state => state.finance.finance.messageVisiable, shallowEqual);

    const setMessage = () => {
        dispatch(setMessageVisiable({visiable: false}));
    }
    
    const visiableActions = useMessageActions(visiable, setMessage);

    return (
        <>
            <Message
                content={message || ""}
                visiable={visiableActions}
                position="top"
            />

            <Finance />
        </>
    )
}

export default withMatch(React.memo(FinanceContainer), [2, 3, 4]);
import React, { FC } from "react";
import { getFirstSymbol } from "../../../../common/helpers/getFirstSymbol";
import styles from "./Avatar.module.css";

interface Props {
  uri?: string;
  name: string;
  background?: string;
  backgroundName?: string;
  width?: number;
  height?: number;
  maxWidthImg?: number | string;
  maxHeightImg?: number | string;
  fontSize?: number;
  lineHeight?: number;
}

const Avatar: FC<Props> = ({
  uri = "",
  name,
  width = 45,
  height = 45,
  background = "#FFF",
  backgroundName = "#4775FE",
  fontSize = 14,
  lineHeight = 16,
  maxWidthImg = 35,
  maxHeightImg = 35,
}) => {
  return (
    <div className={styles.avatarBlock} style={{ width, height, background }}>
      {uri !== "" ? (
        <img
          src={uri}
          alt="avatar"
          className={styles.avatar}
          style={{ maxWidth: maxWidthImg, maxHeight: maxHeightImg }}
        />
      ) : (
        <span
          className={styles.circelAvatar}
          style={{ backgroundColor: backgroundName, fontSize, lineHeight }}
        >
          {getFirstSymbol(name)}
        </span>
      )}
    </div>
  );
};

export default Avatar;

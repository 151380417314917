import { Control, FieldErrors } from "react-hook-form";
import { SelectEmployees } from "../elements/SelectEmployees/SelectEmployees";
import { SelectStudents } from "../elements/SelectStudents/SelectStudents";
import { CalendarEventForm } from "../../../models/CalendarEventForm";

interface CalendarEventTabsProps {
  errors: FieldErrors;
  control: Control<CalendarEventForm>;
}

export const getCalendarEventTabs = ({
  control,
  errors,
}: CalendarEventTabsProps) => [
  {
    key: "1",
    label: "Вчителі",
    children: <SelectEmployees control={control} errors={errors} />,
  },
  {
    key: "2",
    label: "Учні",
    children: <SelectStudents control={control} errors={errors} />,
  },
];

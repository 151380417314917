import { useFormik } from "formik";
import moment from "moment";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { setFonVisiable } from "../../../../../../store/state/all/all.slice";
import { createByVendorFecth } from "../../../../../../store/state/clients/clients.slice";
import { createClientFormVisiable, setSearchClientsFormVisiable, showSkeletonStudent } from "../../../../../../store/state/groups/groups.slice";
import { getNotificationsFetch, NotificationStatuses } from "../../../../../../store/state/notifications/notifications.slice";
import { Filter } from "../../../../../../common/models/Filters";
import { CreateClientFormValues } from "../../../../models/CreateClientFormValues";
import CreateClientForm from "./CreateClientForm";
import validationSchema from "./CreateClientForm.schema";

interface Props {
    isCreationScript: boolean;
}

const CreateClientFormContainer: FC<Props> = ({ isCreationScript }) => {
    const dispatch = useAppDispatch();
    
    const visiable = useAppSelector(state => state.groups.createClientFormVisiable, shallowEqual)
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const initialValues: CreateClientFormValues = {
        name: "",
        surname: "",
        email: "",
        phone: "",
        relationshipStatus: -1,
        discountId: null,
        students: [{ name: "", surname: "", birthday: "", }]
    }

    const formik = useFormik<CreateClientFormValues>({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            const { name, students, surname, email, phone, discountId, relationshipStatus } = values;
            const user = {
                name,
                surname,
                email,
                phone,
                relationshipStatus
            }

            const today = new Date();
            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const filter: Filter = {
                period: {
                    from: moment(firstDay).format("YYYY-MM-DD"),
                    to: moment(lastDay).format("YYYY-MM-DD"),
                }
            }

            dispatch(createByVendorFecth({ vendorId, user, discountId, students, filter }));

            if (!isCreationScript) {
                dispatch(createClientFormVisiable({ visiable: false }));
                dispatch(setSearchClientsFormVisiable({ visiable: true }));
                dispatch(showSkeletonStudent({ visiable: true }));
            } else {
                closeModalHandler();
            }
        },
    });

    const closeModalHandler = () => {
        formik.resetForm();
        dispatch(createClientFormVisiable({ visiable: false }));
        dispatch(setFonVisiable({ visiable: false }));
    }

    return (
        <CreateClientForm
            formik={formik}
            visiable={visiable}
            closeModalHandler={closeModalHandler}
        />
    )
}

export default CreateClientFormContainer;
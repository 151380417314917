import { useFormik } from "formik";
import moment from "moment";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { setFonVisiable } from "../../../../store/state/all/all.slice";
import { createMoneyTransferFetch, setVisiableExchangeForm } from "../../../../store/state/finance/finance.slice";
import { Filter } from "../../../../common/models/Filters";
import ExchangeFormValue from "../../models/ExchangeFormValue";
import ExchangeForm from "./ExchangeForm";
import validationSchema from "./ExchangeForm.scheme";

const ExchangeFormContainer: FC = () => {
    const dispatch = useAppDispatch();

    const visiable = useAppSelector(state => state.finance.visiableExchangeForm, shallowEqual)
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const userId = useAppSelector(state => state.user.id, shallowEqual) || -1;
    const fullname = useAppSelector(state => state.user.name, shallowEqual);
    const roleId = useAppSelector(state => state.user.roleId, shallowEqual) || -1;

    const initialValues: ExchangeFormValue = {
        responsibleUser: {
            id: userId,
            fullname: fullname || "Vendor",
            roleId: roleId,
        },
        sum: 0,
        date: "",
        fromPurseId: -1,
        toPurseId: -1,
        comment: "",
    }

    const formik = useFormik<ExchangeFormValue>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const today = new Date();
            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const filter: Filter = {
                period: {
                    from: moment(firstDay).format("YYYY-MM-DD"),
                    to: moment(lastDay).format("YYYY-MM-DD"),
                }
            }

            dispatch(createMoneyTransferFetch({
                vendorId, transfer: { ...values }, filter
            }));
            closeModalHandler();
        },
    });

    const closeModalHandler = () => {
        formik.resetForm();
        dispatch(setVisiableExchangeForm({ visiable: false }));
        dispatch(setFonVisiable({ visiable: false }));
    }

    return (
        <ExchangeForm
            formik={formik}
            visiable={visiable}
            closeModalHandler={closeModalHandler}
        />
    );
}

export default ExchangeFormContainer;
import { PayloadAction } from "@reduxjs/toolkit";
import { Error } from "../../../../common/models/Error";
import { LocationsState } from "../models/LocationsState";

const setError = (state: LocationsState, action: PayloadAction<Error>) => {
    state.isLoading = false;
    state.isError = action.payload.error;
    state.message = "Виникла помилка";
    state.messageVisiable = true;
}

export default setError;
import { FormikProps } from "formik";
import React, { FC } from "react";
import ModalForm from "../../../../components/dataEntry/forms/ModalForm/ModalForm";
import Checkbox from "../../../../components/dataEntry/inputs/Checkbox/Checkbox";
import Dropdown from "../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import Field from "../../../../components/dataEntry/inputs/Field/Field";
import Button from "../../../../components/general/buttons/Button/Button";
import Row from "../../../../components/layout/Row/Row";
import Wrp from "../../../../components/layout/Wrp/Wrp";
import { Student } from "../../../../store/state/finance/models/CounteragentsAsClients";
import { User } from "../../../../store/state/clients/models/User";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../common/constants/defaultValue";
import OperationsDropdown from "../../../Finance/FinanceForms/AddForm/components/OperationsDropdown/OperationsDropdown";
import PursesDropdown from "../../../Finance/FinanceForms/AddForm/components/PursesDropdown/PursesDropdown";
import StudentsDropdown from "../../../Finance/FinanceForms/AddForm/components/StudentsDropdown/StudentsDropdown";
import { TransactionFormValue } from "../../models/TransactionFormValue";
import UsersDropdown from "./components/UsersDropdown/UsersDropdown";
import GroupsDropdown from "../../../Finance/FinanceForms/AddForm/components/GroupsDropdown/GroupsDropdown";
import PriceField from "./components/PriceField/PriceField";

interface Props {
    formik: FormikProps<TransactionFormValue>;
    visiable: boolean;
    closeModalHandler: () => void;
    users: Pick<User, "id" | "name">[];
    students: Student[];
}

const AddTransactionForm: FC<Props> = ({ visiable, closeModalHandler, formik,
    users, students }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = formik;

    const userId = values.userId;

    const studentId = values.studentId;
    const indexStudent = students.findIndex(student => student.id === studentId);
    const student = students[indexStudent];

    const groupId = values.groupId;
    const groups = (student && student.groups) || [];

    const submitButtonHandler = () => {
        handleSubmit();
    }

    const operationId = values.operationId;
    const purseId = values.purseId;

    const sum = values.sum;

    return (
        <ModalForm
            title="Додати дохід"
            visiable={visiable}
            maxWidth={480}
            handleClick={closeModalHandler}
        >
            <Wrp flexDirection="column" alignItems="center">
                <UsersDropdown
                    userId={userId}
                    users={users}
                    error={errors.userId}
                    touched={touched.userId}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                />

                <Row marginBottom={20}>
                    <PriceField
                        sum={sum}
                        error={errors.sum}
                        touched={touched.sum}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                    />

                    <PursesDropdown
                        purseId={purseId}
                        lable="Рахунок"
                        nameField="purseId"
                        error={errors.purseId}
                        touched={touched.purseId}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                    />
                </Row>
                <Row marginBottom={20}>
                    <OperationsDropdown
                        income={true}
                        operationId={operationId}
                        error={errors.operationId}
                        touched={touched.operationId}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                    />

                    <Field
                        label="Дата надходження"
                        type="date"
                        maxWidth={210}
                        marginBottom={0}
                        marginRight={0}
                        labelLeftShift={0}
                        value={values.date}
                        handleBlur={handleBlur("date")}
                        handleChange={handleChange("date")}
                        touched={touched.date}
                        error={errors.date}
                    />
                </Row>

                <StudentsDropdown
                    label="Учень"
                    student={student}
                    students={students}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    error={errors.studentId}
                    touched={touched.studentId}
                />

                <GroupsDropdown
                    groupId={groupId}
                    groups={groups}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    error={errors.groupId}
                    touched={touched.groupId}
                />

                <Field
                    maxWidth={440}
                    label="Коментар"
                    marginBottom={20}
                    labelLeftShift={0}
                    marginRight={0}
                    value={values.comment}
                    handleBlur={handleBlur("comment")}
                    handleChange={handleChange("comment")}
                    touched={touched.comment}
                    error={errors.comment}
                />

                <Checkbox
                    handleChange={(value) => setFieldValue("scheduled", value)}
                    text="Зробити повторюваним"
                    marginBottom={20}
                />

                <Button
                    disabled={!isValid}
                    maxWidth={160}
                    handleClick={submitButtonHandler}
                    backgroundColor="#4775FE"
                    title="Додати"
                    marginBottom={0}
                    marginRight={15}
                />
            </Wrp>
        </ModalForm>
    );
}

export default AddTransactionForm;
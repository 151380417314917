import { FormikProps } from "formik";
import React, { ChangeEvent, FC } from "react";
import Field from "../../../../components/dataEntry/inputs/Field/Field";
import Column from "../../../../components/layout/Column/Column";
import Row from "../../../../components/layout/Row/Row";
import { LocationValues } from "../models/LocationValues";
import Wrp from "../../../../components/layout/Wrp/Wrp";
import IconFont from "../../../../components/general/icons/IconFont/IconFont";
import Textarea from "../../../../components/dataEntry/inputs/Textarea/Textarea";
import Button from "../../../../components/general/buttons/Button/Button";
import Switch from "../../../../components/dataEntry/inputs/Switch/Switch";
import { SUBMIT_ACTIONS } from "../../../../common/constants/submitActions";
import Gallery from "../../../../components/dataEntry/inputs/Gallery/Gallery";

interface Props {
    formik: FormikProps<LocationValues>;
    isNewItemForm?: boolean;
    searchListCity: string[];
    active?: boolean;
}

const Location: FC<Props> = ({ formik, isNewItemForm = false, searchListCity, active }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = formik;

    const archiveButtonSubmitHandler = () => {
        setFieldValue("submitAction", SUBMIT_ACTIONS.DELETE);
        handleSubmit();
    }

    const sendButtonSubmitHandler = () => {
        setFieldValue("submitAction", SUBMIT_ACTIONS.SAVE);
        handleSubmit();
    }

    const changeTelHandler = (event: ChangeEvent<HTMLInputElement>) => {
        handleChange("phone")(event);
    }

    const images = values.images;
    const files = values.files;
    const links = values.links;

    let gallery: (string | undefined)[] = [undefined, undefined, undefined];
    gallery = gallery.map((image, index) => (images && images[index]) || undefined);

    let filesGallery: (File | undefined)[] = [undefined, undefined, undefined];;
    filesGallery = filesGallery.map((file, index) => (files && files[index]) || undefined);

    const linksGalley = links?.map((link, index) => (links && links[index]) || undefined) || [];

    return (
        <Wrp>
            <Column width={"100%"}>
                <Row marginBottom={20}>
                    <Field
                        label="Назва"
                        maxWidth={280}
                        marginBottom={0}
                        labelLeftShift={0}
                        value={values.name}
                        handleChange={handleChange("name")}
                        handleBlur={handleBlur("name")}
                        error={errors.name}
                        touched={touched.name}
                    />
                    <Field
                        label="Місто"
                        maxWidth={280}
                        marginBottom={0}
                        labelLeftShift={0}
                        value={values.city}
                        handleChange={handleChange("city")}
                        handleBlur={handleBlur("city")}
                        error={errors.city}
                        touched={touched.city}
                        searchList={searchListCity}
                        setFieldValue={(value) => setFieldValue("city", value)}
                    />
                </Row>
                <Row marginBottom={20}>
                    <Field
                        label="Адреса"
                        maxWidth={280}
                        marginBottom={0}
                        labelLeftShift={0}
                        value={values.address}
                        handleChange={handleChange("address")}
                        handleBlur={handleBlur("address")}
                        error={errors.address}
                        touched={touched.address}
                    />
                    <Field
                        icon={<IconFont color="#A6AEC5" iconClass="icon-marker" fontSize={14} />}
                        label="Координати для мапи"
                        marginBottom={0}
                        maxWidth={280}
                        labelLeftShift={0}
                        paddingLeft={40}
                        value={values.coord}
                        handleChange={handleChange("coord")}
                        handleBlur={handleBlur("coord")}
                        error={errors.coord}
                        touched={touched.coord}
                    />
                </Row>
                <Row marginBottom={20}>
                    <Field
                        label="Телефон"
                        maxWidth={280}
                        marginBottom={0}
                        labelLeftShift={0}
                        value={values.phone}
                        handleChange={changeTelHandler}
                        handleBlur={handleBlur("phone")}
                        error={errors.phone || undefined}
                        touched={touched.phone}
                        mask={"+38 (___) ___-__-__"}
                    />

                    <Switch
                        marginRight={20}
                        value={values.confirmed}
                        title="Статус"
                        handleChange={handleChange("confirmed")}
                    />
                </Row>
                <Textarea
                    maxWidth={580}
                    maxHeight={160}
                    title="Опис"
                    value={values.desc}
                    handleChange={handleChange("desc")}
                    handleBlur={handleBlur("desc")}
                    marginBottom={30}
                />
                <Row>
                    <Button
                        disabled={!isValid}
                        backgroundColor="#4775FE"
                        title="Зберегти"
                        handleClick={sendButtonSubmitHandler}
                        marginRight={20}
                    />
                    <>
                        {!isNewItemForm &&
                            <Button
                                backgroundColor="#A6AEC5"
                                title={active ? "Архівувати" : "Активувати"}
                                handleClick={archiveButtonSubmitHandler}
                            />
                        }
                    </>
                </Row>
            </Column>
            <>
                {!isNewItemForm && <Column>
                    <Gallery
                        imgs={gallery}
                        acceptFile=".PNG,.JPG"
                        setFieldValue={setFieldValue}
                        files={filesGallery}
                        links={linksGalley}
                    />
                </Column>
                }
            </>
        </Wrp>
    );
}

export default Location;
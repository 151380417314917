import { useFormik } from "formik";
import React, { FC } from "react";
import Employee from "./Employee";
import validationSchema from "./Employee.scheme";
import { EmployeeValues } from "../models/EmployeeValues";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { createEmployeeFetch, setNewItemId } from "../../../store/state/employees/employees.slice";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { shallowEqual } from "react-redux";
import withMatch from "../../../components/other/hoc/withMatch";
import { convertStringToArray } from "../../../common/helpers/convertStringToArray";
import { Filter } from "../../../common/models/Filters";
import moment from "moment";
import useRedirectToNewItemPage from "../../../common/hooks/useRedirectToNewItemPage";
import useSubmitAction from "../../../common/hooks/useSubmitAction";
import { SUBMIT_ACTIONS } from "../../../common/constants/submitActions";

const NewEmployeeContainer: FC = () => {
    const dispatch = useAppDispatch();
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const newItemId = useAppSelector(state => state.employees.newItemId, shallowEqual);

    const payload = {
        newItemId,
        setNewItemId: (id: number | null) => {
            dispatch(setNewItemId({ id }));
        }
    }

    useRedirectToNewItemPage(payload);

    const initialValues: EmployeeValues = {
        fullname: "",
        startDate: "",
        roleId: 3,
        phone: "",
        email: "",
        salary: 0,
        locationsIds: "",
        active: true,
        salaryForServices: [],
        submitAction: SUBMIT_ACTIONS.SAVE,
    }

    const submitActionHandler = useSubmitAction();

    const formik = useFormik<EmployeeValues>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const { fullname, startDate, roleId, phone, email, salary, locationsIds, salaryForServices, submitAction, active } = values;
            const locationsIdsArray = convertStringToArray(locationsIds);

            const today = new Date();
            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const filter: Filter = {
                period: {
                    from: moment(firstDay).format("YYYY-MM-DD"),
                    to: moment(lastDay).format("YYYY-MM-DD"),
                }
            }

            submitActionHandler(
                submitAction,
                () => createEmployeeFetch(
                    {
                        vendorId,
                        employee: {
                            fullname,
                            startDate,
                            roleId,
                            phone,
                            email,
                            salary,
                            locationsIds: locationsIdsArray,
                            salaryForServices,
                            active,
                        },
                        filter
                    }
                )
            );
        },
    });

    return (
        <Employee
            formik={formik}
            isNewItemForm={true}
        />
    )
}

export default React.memo(withMatch(NewEmployeeContainer, [2, 3, 4]));
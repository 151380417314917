import React from "react";
import SignUp from "./SignUp";
import validationSchema from "./SignUp.scheme"; 
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { registerVendorFetch, setErrorEmailVendor, setErrorNameVendor, setErrorTelVendor } from "../../store/state/user/user.slice";
import { SignUpValues } from "./models/SignUpValues";
import { Error as FormError } from "../../common/models/Error";

const SignUpContainer = () => {
    const emailError = useAppSelector(state => state.user.vendor.emailError, shallowEqual);
    const telError = useAppSelector(state => state.user.vendor.telError, shallowEqual);
    const vendorNameError = useAppSelector(state => state.user.vendor.vendorNameError, shallowEqual);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isSubmit = useAppSelector(state => state.user.vendor.isRegistration, shallowEqual);

    const clickNavButtonHandler = () => {
        navigate("/signin");
    }

    const initialValues: SignUpValues = {
        email: '',
        password: '',
        passwordCopy: '',
        name: '',
        vendorName: '',
        tel: ''
    }

    const formik = useFormik<SignUpValues>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const {email, vendorName, name, tel, password} = values;
            dispatch(registerVendorFetch({email, vendorName, name, tel, password}));
        },
    });

    const setErrorEmailVendorHandler = (payload: FormError) => {
        dispatch(setErrorEmailVendor(payload));
    }

    const setErrorTelVendorHandler = (payload: FormError) => {
        dispatch(setErrorTelVendor(payload));
    }

    const setErrorNameVendorHandler = (payload: FormError) => {
        dispatch(setErrorNameVendor(payload));
    }

    return (
        <SignUp 
            isSubmit={isSubmit}
            emailError={emailError}
            telError={telError}
            vendorNameError={vendorNameError}
            clickNavButtonHandler={clickNavButtonHandler} 
            formik={formik} 
            setErrorEmailVendor={setErrorEmailVendorHandler}
            setErrorTelVendor={setErrorTelVendorHandler}
            setErrorNameVendor={setErrorNameVendorHandler}
        />
    );
}

export default React.memo(SignUpContainer);
import React, { FC, useState } from "react";
import Title from "../../../general/typography/Title/Title";
import styles from "./ListFields.module.css";
import { ArrayHelpers, FieldArray } from "formik";
import { ContextValue } from "./models/ContextValue";
import { PagePagination } from "../../../dataDisplay/table/models/PagePagination";
import { Pagination } from "../../../dataDisplay/table/Table";
import { culcPage } from "../../../../common/helpers/culcPage";

interface Props {
  name: string;
  title?: string;
  maxWidth?: number | string;
  fields: (context: ContextValue) => JSX.Element[];
  marginRight?: number;
  paginated?: boolean;
  paginationKey?: string;
}

const ArrayFields: FC<Props> = ({
  title,
  maxWidth,
  name,
  fields,
  marginRight,
  paginated,
  paginationKey,
}) => {
  const [page, setPage] = useState(1);

  return (
    <div className={styles.listFieldBlock} style={{ maxWidth, marginRight }}>
      {title && <Title level={2} title={title} marginBottom={20} />}
      <ul className={styles.listField}>
        <FieldArray
          name={name}
          render={({ push, remove }: ArrayHelpers) => {
            let values = fields({ push, remove, styles });

            const pages: PagePagination[] = [];
            const pageSize = 25;
            const totalCount = values.length;
            const pageAll = Math.ceil(totalCount / pageSize);

            for (let i = 0; i < pageAll; i++) {
              const pageNumber = i + 1;
              const handleClick = () => {
                setPage(pageNumber);
              };

              pages.push({
                name: pageNumber,
                handleClick,
              });
            }

            const handleRightButtonPagination = () => {
              setPage((prevPage) => prevPage + 1);
            };

            const handleLeftButtonPagination = () => {
              setPage((prevPage) => prevPage - 1);
            };

            if (
              page !== undefined &&
              pageAll !== undefined &&
              totalCount !== undefined &&
              pageSize !== undefined &&
              paginated
            ) {
              const pagesRange = culcPage(page, pageAll, totalCount, pageSize);

              values = values.slice(pagesRange.from, pagesRange.to + 1);
            }

            return (
              <>
                {values}

                {pages && pages.length > 1 && (
                  <Pagination
                    page={page}
                    pages={pages}
                    paginationKey={paginationKey}
                    handleLeftButtonPagination={handleLeftButtonPagination}
                    handleRightButtonPagination={handleRightButtonPagination}
                  />
                )}
              </>
            );
          }}
        />
      </ul>
    </div>
  );
};

export default ArrayFields;

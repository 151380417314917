
export enum CalendarEventDay {
    Monday = "1",
    Tuesday = "2",
    Wednesday = "3",
    Thursday = "4",
    Friday = "5",
    Saturday = "6",
    Sunday = "7"
}

export const calendarEventDayOptionsLabel = {
    [CalendarEventDay.Monday]: "Пн",
    [CalendarEventDay.Tuesday]: "Вт",
    [CalendarEventDay.Wednesday]: "Ср",
    [CalendarEventDay.Thursday]: "Чт",
    [CalendarEventDay.Friday]: "Пт",
    [CalendarEventDay.Saturday]: "Сб",
    [CalendarEventDay.Sunday]: "Нд"
}

export const calendarEventDayOptions = Object.values(CalendarEventDay).map((day) => ({
    label: calendarEventDayOptionsLabel[day],
    value: day,
}))

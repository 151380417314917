import { PayloadAction } from "@reduxjs/toolkit";
import deleteItem from "../../../../helpers/deleteItem";
import { LocationsState } from "../../models/LocationsState";
import { DeleteLocationSuccessPayload } from "../../payload/DeleteLocation/DeleteLocationSuccessPayload";

const deleteLocationSuccess = (state: LocationsState, action: PayloadAction<DeleteLocationSuccessPayload>) => {
    const { id } = action.payload;

    const locations = state.locationsArray;

    state.locationsArray = deleteItem(state, locations, id || -1);

    state.isLoading = true;
    state.isError = null;
}

export default deleteLocationSuccess;
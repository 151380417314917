import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { getVendorClientDataFetch } from "../../store/state/clients/clients.slice";
import { getGroupFetch } from "../../store/state/groups/groups.slice";
import { getServiceFetch } from "../../store/state/services/service/service.slice";
import { SYSTEM_ADMIN_ID } from "../../common/constants/role";

function withLoadingIdElement(Component: React.ComponentType) {
    const ComponentWithLoadingIdElement: FC = () => {
        const dispatch = useAppDispatch();

        const { id } = useParams();
        const itemId = Number(id);

        const location = useLocation();
        const locations = location.pathname.split("/");
        locations.shift();

        const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
        const roleId = useAppSelector(state => state.user.roleId, shallowEqual) || -1;

        useEffect(() => {
            if (roleId > SYSTEM_ADMIN_ID && id) {
                switch (locations[0]) {
                    case "services":
                        if (locations[1].localeCompare("basics") === 0) {
                            dispatch(getServiceFetch({ vendorId, serviceId: itemId }));
                        }
                        break;
                    case "clients":
                        dispatch(getVendorClientDataFetch({ vendorId, clientId: itemId }));
                        break;
                    case "groups":
                        dispatch(getGroupFetch({ vendorId, groupId: itemId }));
                        break;
                }
            }
        }, [vendorId, roleId]);

        return (
            <Component />
        );
    }

    return ComponentWithLoadingIdElement;
}

export default withLoadingIdElement;
import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Flex,
  Form,
  InputNumber,
  Modal,
  Select,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { days, finishOptions, periods } from "./models/ReccurenceModalPeriods";
import { Control, Controller, useFormContext, useWatch } from "react-hook-form";
import { CalendarEventForm } from "../../models/CalendarEventForm";

interface ReccurenceModalProps {
  // errors: FieldErrors;
  control: Control<CalendarEventForm>;
}

const ReccurenceModal: React.FC<ReccurenceModalProps> = ({ control }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState("Ніколи");
  // const [day, setDay] = useState("Monday");

  // const { watch } = useFormContext();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  }

  const choosenPeriod = useWatch({
    control,
    name: "recurrence.interval.period",
  });

  const chosenFinished = useWatch({
    control,
    name: "recurrence.finish",
  });

  // const selectedOption = watch("recurrence.finish");

  console.log({ choosenPeriod });
  console.log({ chosenFinished });
  // console.log({ selectedOption });

  return (
    <>
      <Button type="dashed" onClick={showModal}>
        Виберіть повтори
      </Button>
      <Modal
        title="Налаштувати повтори"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Flex gap="middle" vertical>
          <Flex gap="middle">
            <Typography.Text type="secondary" strong>
              Повторювати кожні:
            </Typography.Text>
            <Form.Item
            // label="Виберіть викладачів"
            // validateStatus={errors.employeesIds ? "error" : ""}
            // help={errors.employeesIds && errors.employeesIds.message}
            >
              <Controller
                name="recurrence.interval.value"
                control={control}
                render={({ field }) => (
                  <InputNumber min={1} max={100} defaultValue={3} {...field} />
                )}
              />
            </Form.Item>

            <Form.Item
            // label="Виберіть викладачів"
            // validateStatus={errors.employeesIds ? "error" : ""}
            // help={errors.employeesIds && errors.employeesIds.message}
            >
              <Controller
                name="recurrence.interval.period"
                control={control}
                render={({ field }) => (
                  <Select
                    style={{ width: 120 }}
                    defaultValue="day"
                    // placeholder="Виберіть викладачів"
                    {...field}
                  >
                    {periods.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
            </Form.Item>

            {/* <Select
              // value={period}
              // defaultValue="day"
              style={{ width: 120 }}
              // onChange={onChangePeriod}
              options={periods}
            /> */}
          </Flex>

          {choosenPeriod === "week" && (
            <Form.Item label="У такі дні:">
              {days.map((item) => (
                <Flex key={item.value} gap="middle">
                  <Controller
                    name="recurrence.interval.additional.week.days"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        // checked={field.value === item.value}
                        value={item.value}
                      >
                        {item.label}
                      </Checkbox>
                    )}
                  />
                </Flex>
              ))}
            </Form.Item>
          )}

          {/* <Typography.Text type="secondary" strong>
            Завершення:
          </Typography.Text> */}
          {/* <Form.Item label="Завершення:">
            <Controller
              name="recurrence.finish"
              control={control}
              // defaultValue="never"
              render={({ field }) => (
                <Checkbox.Group
                  options={finishOptions}
                  // onChange={handleCheckboxChange}
                  // value={chosenFinished}
                  // value={chosenFinished ? [chosenFinished] : []}
                  {...field}
                />
              )}
            />
          </Form.Item> */}

          {/* {finishOptions.map((item) => (
            <Flex key={item.value} gap="middle">
              <Form.Item>
                <Controller
                  name="recurrence.finish"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      // onChange={onChangeFinish}
                      // checked={item.value === value}
                      value={item.value}
                    >
                      {item.label}
                    </Checkbox>
                  )}
                />
              </Form.Item>
              {item.value === "Дата" && (
                <DatePicker
                  disabled={value !== "Дата"}
                  format="YYYY-MM-DD"
                  placeholder="Виберіть дату"
                />
              )}

              {item.value === "Після" && (
                <InputNumber
                  disabled={value !== "Після"}
                  min={1}
                  max={10}
                  defaultValue={3}
                  onChange={onChangeQuantity}
                />
              )}
            </Flex>
          ))} */}
        </Flex>
      </Modal>
    </>
  );
};

export default ReccurenceModal;

import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import InfoView from "../../../../../../components/dataDisplay/InfoView/InfoView";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { useAppDispatch } from "../../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { getSelectedServiceFetch } from "../../../../../../store/state/groups/groups.slice";
import { configAdapter } from "../../../../../../common/adapters/ConfigAdapter";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../common/constants/defaultValue";

interface Props {
    fieldName: string;
    serviceId: number;
    error: string | undefined;
    touched: boolean | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
    visiableDateServices?: boolean;
    maxWidth?: number;
}

const ServicesDropdown: FC<Props> = ({ 
    serviceId, 
    error, 
    touched, 
    setFieldValue, 
    setFieldTouched, 
    fieldName,
    visiableDateServices,
    maxWidth = 420,
}) => {
    const dispatch = useAppDispatch();

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const shortServices = useAppSelector(state => state.services.shortServices, shallowEqual);
    
    const indexShortServices = shortServices.findIndex(shortService => shortService.id === serviceId);

    const defaultShortServices = shortServices[indexShortServices];
    const defaultValueShortServices: DefaultValue = {
        valueInput: (defaultShortServices && String(defaultShortServices.id)) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: defaultShortServices && defaultShortServices.name || DEFAULT_VALUES_DROPDOWN.valueText,
    }

    const shortServiceItems = shortServices.map((shortService, index) => (
        <DropdownItem
            key={"SHORT_SERVICE_ITEM_" + index}
            item={shortService.name}
            valueInput={String(shortService.id)}
            valueText={shortService.name}
        />
    ));

    const types = useAppSelector(state => state.config.vendorConfig?.service.types, shallowEqual);
    const durations = useAppSelector(state => state.config.vendorConfig?.service.duration, shallowEqual);

    const type = types && defaultShortServices && configAdapter(types, defaultShortServices.typeId) || "";
    const durationType = durations && defaultShortServices && configAdapter(durations, defaultShortServices.durationTypeId) || "";
    const duration = defaultShortServices && `${defaultShortServices.duration} ${durationType}` || "";

    const changeHandler = (value: string) => {
        const serviceId = parseInt(value);
        setFieldValue(fieldName, serviceId);
        dispatch(getSelectedServiceFetch({ vendorId, serviceId }))
    }

    const blurHandler = (touched: boolean) => {
        setFieldTouched(fieldName, touched)
    }

    return (
        <>
            <Dropdown
                label="Послуга"
                maxWidth={maxWidth}
                height={40}
                marginRight={20}
                defaultValue={defaultValueShortServices}
                doubleClickDropdown={false}
                values={serviceId}
                error={error}
                touched={touched}
                handleBlur={blurHandler}
                handleChange={changeHandler}
            >
                {shortServiceItems}
            </Dropdown>

            {type !== "" && visiableDateServices &&
                <InfoView
                    marginRight={20}
                    title="Тип"
                    value={type}
                />
            }

            {duration !== "" && visiableDateServices &&
                <InfoView
                    marginRight={20}
                    title="Тривалість"
                    value={duration}
                />
            }
        </>
    );
}

export default ServicesDropdown;
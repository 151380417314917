import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { Routes, Route } from "react-router-dom";

/**
 * Layouts
 */
import MainLayout from "../components/layout/MainLayout/MainLayout";

/**
 * HOCs
 */
import withAuthCheckStatus from "../components/other/hoc/withAuthCheckStatus";

/**
 * Hooks
 */
import { useAppSelector } from "../store/hooks/selector.hook";

/**
 * Containers
 */
import ClientContainer from "./Clients/Client/Client.container";
import ClientsContainer from "./Clients/Clients.container";
import DashboardContainer from "./Dashboard/Dashboard.conatiner";
import NewEmployeeContainer from "./Employees/Employee/NewEmployee.container";
import EmployeesContainer from "./Employees/Employees.container";
import FinanceContainer from "./Finance/Finance.container";
import NoMatchContainer from "./NoMatch/NoMatch.container";
import NotificationsContainer from "./Notifications/Notifications.container";
import PreloaderContainer from "./Preloader/Preloader.container";
import EmailPassRecoveryContainer from "./Recovery/EmailPassRecovery/EmailPassRecovery.container";
import PassRecoveryContainer from "./Recovery/PassRecovery/PassRecovery.container";
import Recovery from "./Recovery/Recovery";
import BasicContainer from "./Services/Basics/Basic/Basic.container";
import NewBasicContainer from "./Services/Basics/Basic/NewBasic.container";
import BasicsContainer from "./Services/Basics/Basics.container";
import Services from "./Services/Services";
import NewUpsellContainer from "./Services/Upsells/Upsell/NewUpsell.container";
import UpsellContainer from "./Services/Upsells/Upsell/Upsell.container";
import UpsellsContainer from "./Services/Upsells/Upsells.container";
import LocationContainer from "./Settings/Locations/Location/Location.container";
import NewLocationContainer from "./Settings/Locations/Location/NewLocation.container";
import LocationsContainer from "./Settings/Locations/Locations.container";
import MoneyContainer from "./Settings/Money/Money.container";
import Settings from "./Settings/Settings";
import SignInContainer from "./SignIn/SignIn.container";
import SignUpContainer from "./SignUp/SignUp.container";
import VendorsContainer from "./Vendors/Vendors.container";
import CreateNewEventForm from "./Event/CreateNewEventForm/CreateNewEventForm";
import EventsCalendar from "./ EventsCalendar/EventsCalendar";
import Employee from "./Employee/Employee";

const MainLayoutWithAuth = withAuthCheckStatus(MainLayout);

const Screens: FC = () => {
  const isLoadingData = useAppSelector(
    (state) => state.all.isLoadingData,
    shallowEqual
  );

  return (
    <Routes>
      <Route path="signin" element={<SignInContainer />} />
      <Route path="signup" element={<SignUpContainer />} />
      <Route path="recovery" element={<Recovery />}>
        <Route path="pass-email" element={<EmailPassRecoveryContainer />} />
        <Route path="pass" element={<PassRecoveryContainer />} />
      </Route>
      <Route
        path="/"
        element={
          !isLoadingData ? <PreloaderContainer /> : <MainLayoutWithAuth />
        }
      >
        <Route index element={<DashboardContainer />} />
        <Route path="vendors" element={<VendorsContainer />} />
        <Route path="clients">
          <Route index element={<ClientsContainer />} />
          <Route path=":id/communication" element={<ClientContainer />} />
          <Route path=":id/money" element={<ClientContainer />} />
        </Route>
        <Route path="finance" element={<FinanceContainer />} />
        <Route path="services" element={<Services />}>
          <Route path="basics">
            <Route index element={<BasicsContainer />} />
            <Route path=":id" element={<BasicContainer />} />
            <Route path="new" element={<NewBasicContainer />} />
          </Route>
          <Route path="upsells">
            <Route index element={<UpsellsContainer />} />
            <Route path=":id" element={<UpsellContainer />} />
            <Route path="new" element={<NewUpsellContainer />} />
          </Route>
        </Route>
        <Route path="settings" element={<Settings />}>
          <Route path="locations">
            <Route index element={<LocationsContainer />} />
            <Route path=":id" element={<LocationContainer />} />
            <Route path="new" element={<NewLocationContainer />} />
          </Route>
          <Route path="money" element={<MoneyContainer />} />
          <Route path="employees">
            <Route index element={<EmployeesContainer />} />
            <Route path=":id" element={<Employee />} />
            <Route path="new" element={<NewEmployeeContainer />} />
          </Route>
          <Route path="services" element={<Services />}>
            <Route path="basics">
              <Route index element={<BasicsContainer />} />
              <Route path=":id" element={<BasicContainer />} />
              <Route path="new" element={<NewBasicContainer />} />
            </Route>
            <Route path="upsells">
              <Route index element={<UpsellsContainer />} />
              <Route path=":id" element={<UpsellContainer />} />
              <Route path="new" element={<NewUpsellContainer />} />
            </Route>
          </Route>
        </Route>
        <Route path="createEvent">
          <Route index element={<CreateNewEventForm />} />
        </Route>
        <Route path="notifications" element={<NotificationsContainer />} />
        <Route path="calendar" element={<EventsCalendar />} />
      </Route>
      <Route path="*" element={<NoMatchContainer />} />
    </Routes>
  );
};

export default Screens;

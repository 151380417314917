import React, { ChangeEvent, FC, FocusEvent } from "react";
import Title from "../../../general/typography/Title/Title";
import styles from "./Textarea.module.css";
import classNames from "classnames";
import { isValid } from "../../../../common/helpers/isValid";

const cx = classNames.bind(styles);

interface Props {
  title?: string;
  placeholder?: string;
  value?: string;
  maxWidth?: number;
  marginBottom?: number;
  marginRight?: number;
  maxHeight: number;
  handleChange?: (event: ChangeEvent<any>) => void;
  handleBlur?: (event: FocusEvent<any>) => void;
  handleFocus?: (event: FocusEvent<any>) => void;
  error?: string | undefined;
  touched?: boolean | undefined;
}

const Textarea: FC<Props> = ({
  placeholder,
  marginBottom,
  maxWidth,
  maxHeight,
  title,
  handleChange,
  handleBlur,
  value,
  error,
  touched,
  marginRight,
}) => {
  const valid = isValid(error, touched);
  return (
    <label
      style={{ maxWidth, marginBottom, marginRight }}
      className={styles.label}
    >
      {title && (
        <Title title={title} level={6} color="#A6AEC5" marginBottom={5} />
      )}
      <textarea
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        style={{ maxHeight, minHeight: maxHeight }}
        className={cx(styles.textarea, !valid && styles.error)}
        placeholder={placeholder}
      ></textarea>
    </label>
  );
};

export default Textarea;

import { useLocation, useNavigate } from "react-router-dom";

type TDeleteRedirect = () => ReturnType<typeof setTimeout>;

export function useDeleteRedirect(redirectUrl = "", timeout = 500): TDeleteRedirect {
    const navigate = useNavigate();
    const location = useLocation();
    const locations = location.pathname.split("/");
    locations.shift();
    
    const path = locations.length > 2 ? `${locations[0]}/${locations[1]}` : locations[0];
    
    return () => setTimeout(() => {
            redirectUrl === "" ? navigate(`/${path}`) : navigate(`/${redirectUrl}`);
        }, timeout);
}
import React, { FC } from "react";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { Group } from "../../../../../../store/state/finance/models/CounteragentsAsClients";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../common/constants/defaultValue";

interface Props {
    groupId: number;
    groups: Group[];
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
    error: string | undefined;
    touched: boolean | undefined;
}

const GroupsDropdown: FC<Props> = ({ groupId, groups, setFieldTouched, setFieldValue, error, touched }) => {
    const indexGroup = groups.findIndex(group => group.id === groupId);

    const defaultGroup = groups[indexGroup];
    const defaultValueGroup: DefaultValue = {
        valueInput: (defaultGroup && String(defaultGroup.id)) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: (defaultGroup && `${defaultGroup.name} / ${defaultGroup.serviceName}`) || DEFAULT_VALUES_DROPDOWN.valueText,
    }

    const groupsItems = groups.map(group => {
        const name = `${group.name} / ${group.serviceName}`;

        return (
            <DropdownItem
                item={name}
                valueInput={String(group.id)}
                valueText={name}
            />
        )
    });

    const blurHandler = (touched: boolean) => {
        setFieldTouched("groupId", touched)
    }

    const changeHandler = (groupId: string) => {
        setFieldValue("groupId", parseInt(groupId))
    }

    return (
        <Dropdown
            maxWidth={440}
            marginBottom={20}
            label="Курс / Група"
            defaultValue={defaultValueGroup}
            doubleClickDropdown={false}
            handleBlur={blurHandler}
            handleChange={changeHandler}
            error={error}
            touched={touched}
        >
            {groupsItems}
        </Dropdown>
    );
}

export default GroupsDropdown;
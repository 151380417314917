import { AxiosResponse } from "axios"
import instance from "../instance";
import {
    ChangeUpsellActivityRequest,
    CreateUpsellRequest,
    DeleteUpsellRequest,
    GetUpsellRequest,
    GetUpsellsListRequest,
    UpdateUpsellRequest,
    UploadUpsellIconRequest
} from "./Upsells.request";
import {
    CreateResponse,
    GetUpsellResponse,
    GetUpsellsListResponse,
    UploadUpsellIconResponse
} from "./Upsells.response";

interface Upsells {
    create: (data: CreateUpsellRequest) => Promise<AxiosResponse<CreateResponse>>;
    update: (data: UpdateUpsellRequest) => Promise<AxiosResponse<unknown>>;
    getUpsell: (data: GetUpsellRequest) => Promise<AxiosResponse<GetUpsellResponse>>;
    getUpsellsList: (data: GetUpsellsListRequest) => Promise<AxiosResponse<GetUpsellsListResponse>>;
    delete: (data: DeleteUpsellRequest) => Promise<AxiosResponse>;
    changeUpsellActivity: (data: ChangeUpsellActivityRequest) => Promise<AxiosResponse>;
    uploadUpsellIcon: (data: UploadUpsellIconRequest) => Promise<AxiosResponse<UploadUpsellIconResponse>>;
}

export const Upsells: Upsells = {
    create: async (data) => {
        const { vendorId, upsell } = data;

        return instance.post(`/vendors/${vendorId}/upsells`, upsell);
    },
    update: async (data) => {
        const { vendorId, upsell } = data;

        return instance.put(`/vendors/${vendorId}/upsells/${upsell.id}`, upsell);
    },
    getUpsell: async (data) => {
        const { vendorId, upsellId } = data;

        return instance.get(`/vendors/${vendorId}/upsells/${upsellId}`);
    },
    getUpsellsList: async (data) => {
        const { vendorId, filter } = data;

        return instance.get(`/vendors/${vendorId}/upsells`, {
            params: {
                rangeFrom: filter?.range?.from,
                rangeTo: filter?.range?.to,
                confirmed: filter?.confirmed,
                active: filter?.active,
            }
        });
    },
    delete: async (data) => {
        const { vendorId, upsellId } = data;

        return instance.delete(`/vendors/${vendorId}/upsells/${upsellId}`);
    },
    changeUpsellActivity: (data) => {
        const { vendorId, upsellId, active } = data;

        return instance.put(`/vendors/${vendorId}/upsells/${upsellId}/active`, { active });
    },
    uploadUpsellIcon: (data) => {
        const { vendorId, upsellId, file } = data;

        const formData = new FormData();
        formData.append('file', file)

        return instance.post(`/vendors/${vendorId}/upsells/${upsellId}/images`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }
}
import React, { FC } from "react";
import Dropdown from "../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../common/constants/defaultValue";

export interface DropdownFilterItem {
    name: string;
    value: string;
}

interface Props {
    label: string;
    dropdownList: DropdownFilterItem[];
    changeHandler: (value: string) => void;
    value: string;
    maxWidth: number;
}

const DropdownFilter: FC<Props> = ({ dropdownList, changeHandler, value, label, maxWidth }) => {
    const indexItem = dropdownList.findIndex(dropdownItem => dropdownItem.value.localeCompare(value) === 0);
    const defaultDurationType = dropdownList[indexItem];

    const defaultName = defaultDurationType?.name;
    const defaultValue = (defaultDurationType && String(defaultDurationType.value)) || null;
    const valueInput = defaultValue || DEFAULT_VALUES_DROPDOWN.valueInput;
    const valueText = defaultName || DEFAULT_VALUES_DROPDOWN.valueText;

    const defaultDropdownValue: DefaultValue = {
        valueInput,
        valueText,
    }

    const dropdownItems = dropdownList.map(dropdownItem => {
        const name = dropdownItem.name;
        const value = dropdownItem.value;

        return (
            <DropdownItem
                item={name}
                valueInput={value}
                valueText={name}
            />
        )
    });

    return (
        <Dropdown
            label={label}
            maxWidth={maxWidth}
            marginRight={20}
            defaultValue={defaultDropdownValue}
            handleChange={changeHandler}
            doubleClickDropdown={true}
        >
            {dropdownItems}
        </Dropdown>
    )
}

export default DropdownFilter;
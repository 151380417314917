import { useFormik } from "formik";
import React, { useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import withMatch from "../../../../components/other/hoc/withMatch";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { changeServiceActivityFetch, updateServicesFetch, uploadServiceImagesFetch } from "../../../../store/state/services/service/service.slice";
import { SUBMIT_ACTIONS } from "../../../../common/constants/submitActions";
import { convertStringToArray } from "../../../../common/helpers/convertStringToArray";
import { useDeleteRedirect } from "../../../../common/hooks/useDeleteRedirect";
import useNoMatch from "../../../../common/hooks/useNoMatch";
import useSubmitAction from "../../../../common/hooks/useSubmitAction";
import { BasicValues } from "../models/BasicValues";
import BasicMessage from "./Basic.message";
import validationSchema from "./Basic.scheme";

const BasicContainer = () => {
    let { id } = useParams();
    const basicId = Number(id);
    const basics = useAppSelector(state => state.services.pageData, shallowEqual);

    const indexBasic = basics.findIndex(basic => basic.id === basicId);
    const basic = basics[indexBasic];

    const deleteRedirect = useDeleteRedirect();

    const noMatch = useNoMatch(basic);

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const active = basic?.active;

    const initialValues: BasicValues = basic ? {
        name: basic.name,
        active: active,
        categoryId: Number(basic.categoryId),
        subCategoryId: basic.subCategoryId,
        description: basic.description,
        duration: basic.duration,
        durationTypeId: basic.durationTypeId,
        typeId: basic.typeId,
        locationsIds: basic.locationsIds.join(","),
        lessons: basic.lessons.join("[/]"),
        upsellsIds: basic.upsellsIds.join(","),
        images: basic.images || [],
        files: [],
        links: [],
        submitAction: SUBMIT_ACTIONS.SAVE,
        confirmed: basic.confirmed
    } : {
        name: "",
        active: false,
        categoryId: -1,
        subCategoryId: -1,
        description: "",
        duration: 1,
        durationTypeId: 1,
        typeId: 1,
        locationsIds: "",
        lessons: "Заняття 1",
        upsellsIds: "",
        confirmed: false,
        images: [],
        files: [],
        links: [],
        submitAction: SUBMIT_ACTIONS.SAVE,
    }

    const submitActionHandler = useSubmitAction(active ? deleteRedirect : undefined);

    const submitFormHandler = (values: BasicValues) => {
        const {
            submitAction, name, active, categoryId, subCategoryId, description, duration,
            durationTypeId, typeId, locationsIds, lessons, upsellsIds, confirmed, files, links,
        } = values;

        const locationsIdsArray = convertStringToArray(locationsIds);

        const lessonsArray = lessons.split("[/]");
        const upsellsIdsArray = upsellsIds.split(",").map(item => Number(item));

        submitActionHandler(
            submitAction,
            () => updateServicesFetch(
                {
                    vendorId,
                    serviceId: basicId,
                    service: {
                        name,
                        active,
                        categoryId: Number(categoryId),
                        subCategoryId,
                        description,
                        duration,
                        durationTypeId,
                        typeId,
                        locationsIds: locationsIdsArray,
                        lessons: lessonsArray,
                        upsellsIds: upsellsIdsArray,
                        confirmed,
                    }
                }
            ),
            () => changeServiceActivityFetch(
                { vendorId, serviceId: basicId, active: !active }
            ),
            () => uploadServiceImagesFetch({
                serviceId: basicId,
                vendorId,
                files: files?.filter(file => file !== undefined) || [],
                links: links?.filter(link => link !== undefined) || [],
            })
        );

        setFieldValue("files", undefined);
        setFieldValue("links", undefined);
    }

    const formik = useFormik<BasicValues>({
        initialValues,
        validationSchema,
        onSubmit: (values) => submitFormHandler(values),
    });

    const { setFieldValue } = formik;

    useEffect(() => {
        setFieldValue("images", basic.images);
    }, [basic.images]);

    if (noMatch.isNoMatch) {
        return noMatch.noMatchComponent
    }

    return (
        <BasicMessage
            formik={formik}
            active={active}
        />
    );
}

export default React.memo(withMatch(BasicContainer, [2, 3, 4]));
import React, { FC, MouseEvent } from "react";
import { PagePagination } from "../../../../components/dataDisplay/table/models/PagePagination";
import Table from "../../../../components/dataDisplay/table/Table";
import Title from "../../../../components/general/typography/Title/Title";
import { DATA_INDEX_BASIC_ACTIVE_SERVICES } from "../../../../common/constants/tables";
import { BasicsColumn } from "../../../Services/Basics/models/BasicsColumn";

interface Props {
    data: BasicsColumn[];
    page: number;
    pages: PagePagination[];
    handleLeftButtonPagination: () => void;
    handleRightButtonPagination: () => void;
    handleClickRow: (item: any, event: MouseEvent<HTMLTableRowElement>) => void;
}

const ServicesTableBlock: FC<Props> = ({data, page, pages, handleLeftButtonPagination, handleRightButtonPagination, handleClickRow}) => {
    return (
        <>
            <Title marginBottom={15} title="Активні послуги" level={2} />
            <Table 
                handleClickRow={handleClickRow}
                data={data} 
                columns={DATA_INDEX_BASIC_ACTIVE_SERVICES} 
                page={page}
                pages={pages}
                handleLeftButtonPagination={handleLeftButtonPagination}
                handleRightButtonPagination={handleRightButtonPagination} 
                paginated={true}
            />
        </>
    )
}

export default ServicesTableBlock;
import { FormikProps, FormikProvider } from "formik";
import React, { FC } from "react";
import Field from "../../../components/dataEntry/inputs/Field/Field";
import Button from "../../../components/general/buttons/Button/Button";
import Row from "../../../components/layout/Row/Row";
import Wrp from "../../../components/layout/Wrp/Wrp";
import LocationsIdsTagInput from "../../Services/Basics/Basic/components/LocationsTagInput/LocationsTagInput";
import { EmployeeValues } from "../models/EmployeeValues";
import ButtonSendPassword from "./components/ButtonSendPassword/ButtonSendPassword";
import EmailField from "./components/EmailField/EmailField";
import PhoneField from "./components/PhoneField/PhoneField";
import RolesDropdown from "./components/RolesDropdown/RolesDropdown";
import SalaryForServicesList from "./components/SalaryForServices/SalaryForServices";
import { SUBMIT_ACTIONS } from "../../../common/constants/submitActions";

interface Props {
    formik: FormikProps<EmployeeValues>;
    isNewItemForm?: boolean;
    active?: boolean;
}

const Employee: FC<Props> = ({ formik, isNewItemForm = false, active }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = formik;

    const archiveButtonSubmitHandler = () => {
        setFieldValue("submitAction", SUBMIT_ACTIONS.DELETE);
        handleSubmit();
    }

    const sendButtonSubmitHandler = () => {
        setFieldValue("submitAction", SUBMIT_ACTIONS.SAVE);
        handleSubmit();
    }

    const locationsIdsValues = values.locationsIds;

    const rolesId = Number(values.roleId);

    const email = values.email;

    const phone = values.phone;

    const salaryForServices = values.salaryForServices;

    return (
        <FormikProvider value={formik}>
            <Wrp maxWidth={790} flexDirection="column">
                <Row marginBottom={30}>
                    <Field
                        label="Прізвище та Ім’я працівника"
                        maxWidth={420}
                        marginBottom={0}
                        labelLeftShift={0}
                        value={values.fullname}
                        handleChange={handleChange("fullname")}
                        handleBlur={handleBlur("fullname")}
                        error={errors.fullname}
                        touched={touched.fullname}
                    />

                    <Field
                        label="Дата старту"
                        type="date"
                        maxWidth={170}
                        marginBottom={0}
                        labelLeftShift={0}
                        value={values.startDate}
                        handleChange={handleChange("startDate")}
                        handleBlur={handleBlur("startDate")}
                        error={errors.startDate}
                        touched={touched.startDate}
                    />

                    <RolesDropdown
                        rolesId={rolesId}
                        error={errors.roleId}
                        touched={touched.roleId}
                        setFieldValue={setFieldValue}
                    />
                </Row>

                <Row marginBottom={30} alignItems="flex-end">
                    <PhoneField
                        phone={phone}
                        handleBlur={handleBlur("phone")}
                        handleChange={handleChange}
                        error={errors.phone}
                        touched={touched.phone}
                    />

                    <EmailField
                        email={email}
                        handleBlur={handleBlur("email")}
                        handleChange={handleChange}
                        error={errors.email}
                        touched={touched.email}
                    />

                    <ButtonSendPassword
                        isValid={isValid}
                        isNewItemForm={isNewItemForm}
                    />
                </Row>

                <Row alignItems="flex-end" marginBottom={30}>
                    <LocationsIdsTagInput
                        marginRight={20}
                        locationsIdsValues={locationsIdsValues}
                        error={errors.locationsIds}
                        touched={touched.locationsIds}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur("locationsIds")}
                    />

                    <Field
                        label="Зарплатня за місяць"
                        type="number"
                        maxWidth={160}
                        marginBottom={0}
                        labelLeftShift={0}
                        paddingLeft={35}
                        value={values.salary}
                        handleChange={handleChange("salary")}
                        handleBlur={handleBlur("salary")}
                        error={errors.salary}
                        touched={touched.salary}
                        topPositionIcon={29.2}
                        icon={<span style={{ fontSize: 14, fontWeight: "500" }}>₴</span>}
                    />
                </Row>

                <Row marginBottom={30}>
                    <SalaryForServicesList
                        salaryForServices={salaryForServices}
                        error={errors.salaryForServices}
                        touched={touched.salaryForServices}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                    />
                </Row>

                <Row>
                    <Button
                        disabled={!isValid}
                        backgroundColor="#4775FE"
                        title="Зберегти"
                        handleClick={sendButtonSubmitHandler}
                        marginRight={20}
                    />
                    <>
                        {!isNewItemForm &&
                            <Button
                                backgroundColor="#A6AEC5"
                                title={active ? "Архівувати" : "Активувати"}
                                handleClick={archiveButtonSubmitHandler}
                            />
                        }
                    </>
                </Row>
            </Wrp>
        </FormikProvider>
    )
}

export default Employee;
import { FC } from "react";
import Wrp from "../../../components/layout/Wrp/Wrp";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./validationSchema";
import { Form, Input, Button, Row, Col, DatePicker, Flex } from "antd";
import { CalendarEventForm } from "./models/CalendarEventForm";
import { CalendarEventTabs } from "./components/CalendarEventTabs/CalendarEventTabs";
import { useGetEmployeesQuery } from "../../../store/api/employees.api";
import { useGetCalendarsListQuery } from "../../../store/api/calendar.api";
import ReccurenceModal from "./components/ReccurenceModal/ReccurenceModal";

interface CreateNewEventFormProps {}

const CreateNewEventForm: FC<CreateNewEventFormProps> = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CalendarEventForm>({
    // resolver: yupResolver(validationSchema),
  });

  const { data, error, isLoading } = useGetEmployeesQuery({
    vendorId: 67,
  });

  const { data: calendarData, error: calendarError } = useGetCalendarsListQuery(
    { vendorId: 67 }
  );

  const onSubmit = (data: CalendarEventForm) => {
    console.log("On Submit");
    console.log({ data });
  };

  return (
    <Wrp flexDirection="column">
      <Form
        onFinish={handleSubmit(onSubmit)}
        style={{ width: "100%" }}
        layout="vertical"
      >
        <Row gutter={45}>
          <Col span={12}>
            <Form.Item
              label="Назва"
              name="name"
              validateStatus={errors.name ? "error" : ""}
              help={errors.name && errors.name.message}
            >
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder="Додайте назву" />
                )}
              />
            </Form.Item>

            <Form.Item
              label="Початок"
              name="startDate"
              validateStatus={errors.name ? "error" : ""}
              help={errors.startDate && errors.startDate.message}
            >
              <Controller
                control={control}
                name="startDate"
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    placeholder="Виберіть дату та час початку події"
                    style={{ width: "100%" }}
                    showTime={{
                      format: "HH:mm",
                    }}
                    format="YYYY-MM-DD HH:mm"
                  />
                )}
              />
            </Form.Item>

            <Form.Item
              label="Кінець"
              name="endDate"
              validateStatus={errors.name ? "error" : ""}
              help={errors.endDate && errors.endDate.message}
            >
              <Controller
                control={control}
                name="endDate"
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    placeholder="Виберіть дату та час завершення події"
                    style={{ width: "100%" }}
                    showTime={{
                      format: "HH:mm",
                    }}
                    format="YYYY-MM-DD HH:mm"
                  />
                )}
              />
            </Form.Item>
            <ReccurenceModal control={control} />
          </Col>
          <Col span={12}>
            <CalendarEventTabs errors={errors} control={control} />
          </Col>
        </Row>
        <Flex justify="flex-end">
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Створити подію
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Wrp>
  );
};

export default CreateNewEventForm;

import { PayloadAction } from "@reduxjs/toolkit";
import { Error } from "../../../../common/models/Error";
import { FinanceState } from "../models/FinanceState";

const setError = (state: FinanceState, action: PayloadAction<Error>) => {
    state.isLoading = false;
    state.isError = action.payload.error;
    state.finance.message = "Виникла помилка";
    state.finance.messageVisiable = true;
}

export default setError;
import { FC } from "react";
import { getCalendarEventTabs } from "./utils/getCalendarEventTabs";
import { Tabs } from "antd";
import { Control, FieldErrors } from "react-hook-form";
import { CalendarEventForm } from "../../models/CalendarEventForm";

interface CalendarEventTabsProps {
  errors: FieldErrors;
  control: Control<CalendarEventForm>;
}
export const CalendarEventTabs: FC<CalendarEventTabsProps> = ({
  errors,
  control,
}) => {
  const tabs = getCalendarEventTabs({ errors, control });

  return <Tabs items={tabs} />;
};

import { FC } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../../../../components/general/buttons/Button/Button";
import { useAppDispatch } from "../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import { sendPasswordFetch } from "../../../../../store/state/employees/employees.slice";

interface Props {
    isValid: boolean;
    isNewItemForm: boolean;
}

const ButtonSendPassword: FC<Props> = ({ isValid, isNewItemForm }) => {
    const { id } = useParams();
    const employeeId = Number(id);
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const dispatch = useAppDispatch();

    const sendEmailPasswordHandler = () => {
        dispatch(sendPasswordFetch({ employeeId, vendorId }));
    }

    return (
        <Button
            disabled={!isValid || isNewItemForm}
            backgroundColor="#4775FE"
            title="Надіслати пароль"
            handleClick={sendEmailPasswordHandler}
            marginRight={20}
        />
    )
}

export default ButtonSendPassword;
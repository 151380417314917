import { useFormik } from "formik";
import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import validationSchema from "./Location.scheme";
import { changeLocationActivityFetch, updateLocationFetch, uploadLocationImagesFetch } from "../../../../store/state/locations/locations.slice";
import { LocationValues } from "../models/LocationValues";
import { useDeleteRedirect } from "../../../../common/hooks/useDeleteRedirect";
import json from "../../../../assets/json/lang_ua.json";
import { TypeList } from "../models/TypeList";
import withMatch from "../../../../components/other/hoc/withMatch";
import LocationMessage from "./Location.message";
import useNoMatch from "../../../../common/hooks/useNoMatch";
import useSubmitAction from "../../../../common/hooks/useSubmitAction";
import { SUBMIT_ACTIONS } from "../../../../common/constants/submitActions";

const LocationContainer: FC = () => {
    let { id } = useParams();
    const locationId = (id && Number(id)) || -1;

    const locations = useAppSelector(state => state.locations.locationsArray, shallowEqual);

    const indexLocation = locations.findIndex(location => location.id === locationId);
    const location = locations[indexLocation];

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const noMatch = useNoMatch(location);

    const deleteRedirect = useDeleteRedirect();

    const searchList = (Array.isArray(json) && json.map((item: TypeList) => item.city_name)) || [];

    const active = location?.active;

    const initialValues: LocationValues = location ? {
        name: location.name,
        address: location.address,
        city: location.city,
        desc: location.description,
        coord: location.coordinates.map(number => {
            if (number % 1 === 0) {
                return number.toFixed(1);
            }

            return number;
        }).join(", "),
        confirmed: location.confirmed,
        phone: location.phone,
        images: location.images || [],
        files: [],
        links: [],
        active,
        submitAction: SUBMIT_ACTIONS.SAVE
    } : {
        name: "",
        address: "",
        city: "",
        desc: "",
        coord: "0.0, 0.0",
        phone: "",
        confirmed: false,
        active: false,
        images: [],
        files: [],
        links: [],
        submitAction: SUBMIT_ACTIONS.SAVE,
    }

    const submitActionHandler = useSubmitAction(active ? deleteRedirect : undefined);

    const formik = useFormik<LocationValues>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const { name, address, city, coord, desc, submitAction, confirmed, phone, files, links, active } = values;
            const coordinates = coord.split(", ").map(item => Number(item));

            submitActionHandler(
                submitAction,
                () => updateLocationFetch({
                    vendorId,
                    locationId,
                    location: {
                        address,
                        city,
                        name,
                        description: desc,
                        coordinates: [coordinates[0], coordinates[1]],
                        confirmed,
                        phone,
                        active,
                    }
                }),
                () => changeLocationActivityFetch(
                    { locationId, vendorId, active: !active }
                ),
                () => uploadLocationImagesFetch({
                    locationId,
                    vendorId,
                    files: files?.filter(file => file !== undefined) || [],
                    links: links?.filter(link => link !== undefined) || [],
                })
            );
            
            setFieldValue("files", undefined);
            setFieldValue("links", undefined);
        },
    });

    const { setFieldValue } = formik;

    useEffect(() => {
        setFieldValue("images", location.images);
    }, [location.images]);

    if (noMatch.isNoMatch) {
        return noMatch.noMatchComponent
    }

    return (
        <LocationMessage
            formik={formik}
            searchListCity={searchList}
            active={active}
        />
    );
}

export default React.memo(withMatch(LocationContainer, [2, 3, 4]));
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { configListAdapter } from "../../../../../../common/adapters/ConfigListAdapter";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../common/constants/defaultValue";

interface Props {
    categoryId: number;
    error: string | undefined;
    touched: boolean | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
}

const CategoryDropdown: FC<Props> = ({ categoryId, error, touched, setFieldValue, setFieldTouched }) => {
    let categoris = useAppSelector(state => state.config.vendorConfig?.service.categories, shallowEqual);
    const categoryIdsList = configListAdapter(categoris);

    const indexCategory = categoryIdsList.findIndex(category => category.id === categoryId);

    const defaultCategory = categoryIdsList[indexCategory];
    const defaultValueCategory: DefaultValue = {
        valueInput: (defaultCategory && String(defaultCategory.id)) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: defaultCategory && defaultCategory.name || DEFAULT_VALUES_DROPDOWN.valueText,
    }

    const categoryItems = categoryIdsList.map(category => {
        const name = category.name;
        const id = String(category.id);
        
        return (
            <DropdownItem
                item={name}
                valueInput={String(category.id)}
                valueText={id}
            />
        )
    });

    const changeHandler = (typeId: string) => {
        setFieldValue("categoryId", parseInt(typeId));
    }

    const blurHandler = (touched: boolean) => {
        setFieldTouched("categoryId", touched)
    }

    return (
        <Dropdown
            label="Категорія"
            maxWidth={170}
            marginRight={20}
            doubleClickDropdown={false}
            defaultValue={defaultValueCategory}
            handleChange={changeHandler}
            handleBlur={blurHandler}
            error={error}
            touched={touched}
        >
            {categoryItems}
        </Dropdown>
    )
}

export default CategoryDropdown;
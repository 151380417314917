import { createSlice } from '@reduxjs/toolkit';
import { ServiceState } from './models/ServiceState';
import createServicesFetchReducer from './reducer/createServices/createServicesFetch';
import createServicesSuccessReducer from './reducer//createServices/createServicesSuccess';
import updateServicesFetchReducer from './reducer/updateServices/updateServicesFetch';
import updateServicesSuccessReducer from './reducer/updateServices/updateServicesSuccess';
import getServicesListFetchReducer from './reducer/getServicesList/getServicesListFetch';
import getServicesListSuccessReducer from './reducer/getServicesList/getServicesListSuccess';
import getShortServicesListFetchReducer from './reducer/getShortServicesList/getShortServicesListFetch';
import getShortServicesListSuccessReducer from './reducer/getShortServicesList/getShortServicesListSuccess';
import getServiceFetchReducer from './reducer/getService/getServiceFetch';
import getServiceSuccessReducer from './reducer/getService/getServiceSuccess';
import deleteServiceFetchReducer from './reducer/deleteService/deleteServiceFetch';
import deleteServiceSuccessReducer from './reducer/deleteService/deleteServiceSuccess';
import getAttendanceStatisticFetchReducer from './reducer/getAttendanceStatistic/getAttendanceStatisticFetch';
import getAttendanceStatisticSuccessReducer from './reducer/getAttendanceStatistic/getAttendanceStatisticSuccess';
import setErrorReducer from './reducer/setError';
import setPageServicesReducer from './reducer/setPageServices';
import setMessageReducer from './reducer/setMessage';
import setMessageVisiableReducer from './reducer/setMessageVisiable';
import setNewItemIdReducer from './reducer/setNewItemId';
import setPageDataServicesReducer from './reducer/setPageDataServices';
import resetServicesReducer from './reducer/resetServices';
import setServiceFilterReducer from './reducer/setServiceFilter';
import setServicesReducer from './reducer/setServices';
import { Filter } from '../../../../common/models/Filters';
import changeServiceActivityFetchReducer from './reducer/changeServiceActivity/changeServiceActivityFetch';
import changeServiceActivitySuccessReducer from './reducer/changeServiceActivity/changeServiceActivitySuccess';
import uploadServiceImagesFetchReducer from './reducer/uploadServiceImages/uploadServiceImagesFetch';
import uploadServiceImagesSuccessReducer from './reducer/uploadServiceImages/uploadServiceImagesSuccess';
import deleteServiceImagesReducer from './reducer/deleteServiceImages';

const filter: Filter = {
    categoryId: undefined,
    typeId: undefined,
    active: undefined,
}

export const initialState: ServiceState = {
    basics: [],
    pageData: [],
    shortServices: [],
    attendanceStatistic: [],
    filter,
    isLoading: false,
    isError: null,
    message: null,
    messageVisiable: false,
    getAllItems: false,
    pageAll: 0,
    pageSize: 15,
    page: 1,
    totalCount: 0,
    newItemId: null,
}

export const ServiceSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        createServicesFetch: createServicesFetchReducer,
        createServicesSuccess: createServicesSuccessReducer,
        updateServicesFetch: updateServicesFetchReducer,
        updateServicesSuccess: updateServicesSuccessReducer,
        getServicesListFetch: getServicesListFetchReducer,
        getServicesListSuccess: getServicesListSuccessReducer,
        getShortServicesListFetch: getShortServicesListFetchReducer,
        getShortServicesListSuccess: getShortServicesListSuccessReducer,
        getServiceFetch: getServiceFetchReducer,
        getServiceSuccess: getServiceSuccessReducer,
        deleteServiceFetch: deleteServiceFetchReducer,
        deleteServiceSuccess: deleteServiceSuccessReducer,
        getAttendanceStatisticFetch: getAttendanceStatisticFetchReducer,
        getAttendanceStatisticSuccess: getAttendanceStatisticSuccessReducer,
        setError: setErrorReducer,
        setPageServices: setPageServicesReducer,
        setMessage: setMessageReducer,
        setMessageVisiable: setMessageVisiableReducer,
        setNewItemId: setNewItemIdReducer,
        setPageDataServices: setPageDataServicesReducer,
        resetServices: resetServicesReducer,
        setServiceFilter: setServiceFilterReducer,
        setServices: setServicesReducer,
        changeServiceActivityFetch: changeServiceActivityFetchReducer,
        changeServiceActivitySuccess: changeServiceActivitySuccessReducer,
        uploadServiceImagesFetch: uploadServiceImagesFetchReducer,
        uploadServiceImagesSuccess: uploadServiceImagesSuccessReducer,
        deleteServiceImages: deleteServiceImagesReducer,
    },
});

export const {
    createServicesFetch,
    createServicesSuccess,
    updateServicesFetch,
    updateServicesSuccess,
    deleteServiceFetch,
    deleteServiceSuccess,
    getServiceFetch,
    getServiceSuccess,
    getServicesListFetch,
    getServicesListSuccess,
    setError,
    getAttendanceStatisticFetch,
    getAttendanceStatisticSuccess,
    setPageServices,
    setMessage,
    setMessageVisiable,
    setNewItemId,
    getShortServicesListFetch,
    getShortServicesListSuccess,
    setPageDataServices,
    resetServices,
    setServiceFilter,
    setServices,
    changeServiceActivityFetch,
    changeServiceActivitySuccess,
    uploadServiceImagesFetch,
    uploadServiceImagesSuccess,
    deleteServiceImages,
} = ServiceSlice.actions

export default ServiceSlice.reducer
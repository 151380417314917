import React, { FC, useState } from "react";
import Title from "../../../general/typography/Title/Title";
import styles from "./DataList.module.css";
import "./transition.css";
import classNames from "classnames";
import IconFont from "../../../general/icons/IconFont/IconFont";
import AnimateHeight from "react-animate-height";

const cx = classNames.bind(styles);

interface Props {
    name: string;
    active: Array<JSX.Element>;
    archive: Array<JSX.Element>;
}

const DataList: FC<Props> = ({name, active, archive}) => {
    const [toggle, setToggle] = useState<boolean>(false);
    const clickToggleButtonHandler = () => {
        setToggle(prev => !prev);
    }

    return (
        <div className={styles.dataList}>
            <Title title={name} level={6} marginBottom={15} />
            {active && active.length > 0 && <div className={styles.activeList}>
                {active}
            </div>}
            <AnimateHeight
                duration={500}
                height={toggle ? "auto" : 0}
            >
                {archive.length > 0 && <ul className={styles.archiveList}>
                    {archive}
                </ul>}
            </AnimateHeight>
            {archive.length > 0 && <button 
                onClick={clickToggleButtonHandler} 
                className={styles.btn}
            >
                <div className={cx(styles.iconBtn, toggle && styles.active)}>
                    <IconFont iconClass="icon-arrow" color="#A6AEC5" fontSize={12} />
                </div>
                <span>{toggle ? "Приховати" : "Архів"}</span>
            </button>}
        </div>
    );
}

export default DataList;
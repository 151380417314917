import { AxiosResponse } from "axios";
import instance from "../instance";
import {
    ChangeLocationActivityRequest,
    CreateLocationRequest,
    DeleteLocationImagesRequest,
    DeleteLocationRequest,
    GetLocationRequest,
    GetLocationsDataRequest,
    UpdateLocationRequest,
    UploadLocationImagesRequest
} from "./Locations.request";
import {
    createLocationPayload,
    GetLocationResponse,
    GetLocationsListResponse,
    UploadLocationImagesResponse,
} from "./Locations.response";

interface Locations {
    create: (data: CreateLocationRequest) => Promise<AxiosResponse<createLocationPayload>>;
    getData: (data: GetLocationsDataRequest) => Promise<AxiosResponse<GetLocationsListResponse>>;
    delete: (data: DeleteLocationRequest) => Promise<AxiosResponse>;
    update: (data: UpdateLocationRequest) => Promise<AxiosResponse>;
    changeLocationActivity: (data: ChangeLocationActivityRequest) => Promise<AxiosResponse>;
    getLocation: (data: GetLocationRequest) => Promise<AxiosResponse<GetLocationResponse>>;
    uploadLocationImages: (data: UploadLocationImagesRequest) => Promise<AxiosResponse<UploadLocationImagesResponse>>;
    deleteLocationImages: (data: DeleteLocationImagesRequest) => Promise<AxiosResponse>;
}

export const Locations: Locations = {
    create: async (data) => {
        const { vendorId, location } = data;

        return instance.post(`/vendors/${vendorId}/locations`, location);
    },
    getData: async (data) => {
        const { vendorId, filter } = data;

        return instance.get(`/vendors/${vendorId}/locations`, {
            params: {
                rangeFrom: filter?.range?.from,
                rangeTo: filter?.range?.to,
                active: filter?.active,
            }
        });
    },
    delete: async (data) => {
        const { id, vendorId } = data;

        return instance.delete(`/vendors/${vendorId}/locations/${id}`);
    },
    update: async (data) => {
        const { locationId, vendorId, location } = data;

        return instance.put(`/vendors/${vendorId}/locations/${locationId}`, location);
    },
    uploadLocationImages: (data) => {
        const { vendorId, locationId, files } = data;

        const formData = new FormData();
        files.forEach(file => formData.append('files', file));

        return instance.post(`/vendors/${vendorId}/locations/${locationId}/images`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },
    deleteLocationImages: (data) => {
        const { vendorId, locationId, links } = data;

        return instance.delete(`/vendors/${vendorId}/locations/${locationId}/images`, { data: { links } });
    },
    changeLocationActivity: (data) => {
        const { vendorId, locationId, active } = data;

        return instance.put(`/vendors/${vendorId}/locations/${locationId}/active`, { active });
    },
    getLocation: (data) => {
        const { vendorId, locationId } = data;

        return instance.get(`/vendors/${vendorId}/locations/${locationId}`);
    }
}
export const finishOptions = [
    { label: "never", value: "Ніколи" },
    { label: "date", value: "Дата" },
    { label: "after", value: "Після" },
];

export const days = [
    { label: "Monday", value: "Пн" },
    { label: "Tuesday", value: "Вт" },
    { label: "Wednesday", value: "Ср" },
    { label: "Thursday", value: "Чт" },
    { label: "Friday", value: "Пт" },
    { label: "Saturday", value: "Сб" },
    { label: "Sunday", value: "Нд" },
];

export const periods = [
    { value: "day", label: "День" },
    { value: "week", label: "Тиждень" },
    { value: "month", label: "Місяць" },
    { value: "year", label: "Рік" },
];
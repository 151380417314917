import React, {
  ChangeEvent,
  DragEvent,
  FC,
  FocusEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import DragIcon from "../../../general/icons/DragIcon/DragIcon";
import Title from "../../../general/typography/Title/Title";
import Wrp from "../../../layout/Wrp/Wrp";
import styles from "./DraggableFields.module.css";
import Field from "../Field/Field";
import { Value } from "./models/Value";
import { State } from "./models/State";
import classNames from "classnames";
import { PagePagination } from "../../../dataDisplay/table/models/PagePagination";
import { Pagination } from "../../../dataDisplay/table/Table";
import { culcPage } from "../../../../common/helpers/culcPage";

const cx = classNames.bind(styles);
const DRAG_LIST_KEY = "DRAG_LIST_";

interface Props {
  title?: string;
  values: Array<Value>;
  keyIndex?: string;
  handleChange?: (value: string) => void;
  handleBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  error?: string | undefined;
  touched?: boolean | undefined;
  paginated?: boolean;
  paginationKey?: string;
  pages?: PagePagination[];
  page?: number;
  handleRightButtonPagination?: () => void;
  handleLeftButtonPagination?: () => void;
  pageSize?: number;
  totalCount?: number;
  pageAll?: number;
}

const DraggableField: FC<Props> = ({
  title,
  values,
  keyIndex,
  handleChange,
  handleBlur,
  error,
  touched,
  pages,
  paginated,
  paginationKey,
  page,
  handleLeftButtonPagination,
  handleRightButtonPagination,
  pageSize,
  totalCount,
  pageAll,
}) => {
  const [state, setState] = useState<State>({
    fields: values.map((item) => ({ ...item })),
  });
  const [currentField, setCurrentField] = useState<Value>(values[0]);
  const [draggable, setDraggable] = useState<boolean>(false);
  const [overField, setOverField] = useState<Value | null>(null);
  const dragItem = useRef<HTMLElement | null>(null);
  const overItem = useRef<HTMLElement | null>(null);

  const sortByOrder = (a: Value, b: Value) => {
    return a.order - b.order;
  };

  let fields = state.fields.sort(sortByOrder).map((item, index) => {
    const dragStartHandler = (event: DragEvent<HTMLElement>) => {
      event.stopPropagation();
      setCurrentField(item);
      dragItem.current = event.currentTarget;
      event.dataTransfer.effectAllowed = "move";
    };

    const dragOverHandler = (event: DragEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();

      if (dragItem.current) {
        dragItem.current.style.display = "none";
      }

      const dgIndex = currentField.order;
      const taIndex = item.order;
      const animateName = dgIndex > taIndex ? styles.dragUp : styles.dragDown;

      if (overItem.current && overField?.id !== item.id) {
        overItem.current.classList.remove(styles.dragDown, styles.dragUp);
      }

      if (!event.currentTarget.classList.contains(animateName)) {
        event.currentTarget.classList.add(animateName);
        overItem.current = event.currentTarget;
        setOverField(item);
      }
    };

    const dragEndHandler = (event: DragEvent<HTMLElement>) => {
      event.preventDefault();
      if (dragItem.current) {
        dragItem.current.style.display = "flex";
      }

      if (overItem.current) {
        overItem.current.classList.remove(styles.dragDown, styles.dragUp);
      }

      event.currentTarget.classList.remove(styles.dragDown, styles.dragUp);
      if (dragItem.current) {
        dragItem.current.classList.remove(styles.dragDown, styles.dragUp);
      }
    };

    const dropHandler = (event: DragEvent<HTMLElement>) => {
      if (dragItem.current) {
        dragItem.current.style.display = "flex";
      }

      event.currentTarget.classList.remove(styles.dragDown, styles.dragUp);
      if (dragItem.current) {
        dragItem.current.classList.remove(styles.dragDown, styles.dragUp);
      }

      setState((prevState) => {
        return {
          fields: prevState.fields.map((elem) => {
            if (elem.id === item.id) {
              return { ...elem, order: currentField?.order };
            }

            if (elem.id === currentField.id) {
              return { ...elem, order: item.order };
            }

            return elem;
          }),
        };
      });
    };

    const mouseEnterHandler = () => {
      setDraggable(true);
    };

    const mouseLeaveHandler = () => {
      setDraggable(false);
    };

    const changeValueHandler = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setState((prevState) => {
        return {
          fields: prevState.fields.map((elem) => {
            if (elem.id === item.id) {
              return { ...elem, value: value };
            }

            return elem;
          }),
        };
      });
    };

    return (
      <li
        draggable={draggable}
        onDragStart={dragStartHandler}
        onDragEnd={dragEndHandler}
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        className={cx(styles.listItem)}
        key={DRAG_LIST_KEY + keyIndex + index}
      >
        <Field
          value={item.value}
          marginBottom={0}
          marginRight={10}
          handleChange={changeValueHandler}
          handleBlur={handleBlur}
          error={error && item.value === "" ? error : undefined}
          touched={touched}
          positionTopMessage={-25}
        />
        <div
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
          className={styles.dragButton}
        >
          <DragIcon />
        </div>
      </li>
    );
  });

  if (
    page !== undefined &&
    pageAll !== undefined &&
    totalCount !== undefined &&
    pageSize !== undefined &&
    paginated
  ) {
    const pagesRange = culcPage(page, pageAll, totalCount, pageSize);
    fields = fields.slice(pagesRange.from, pagesRange.to + 1);
  }

  useEffect(() => {
    setState({ fields: values.map((item) => ({ ...item })) });
    setCurrentField(values[0]);
  }, [values]);

  useEffect(() => {
    if (handleChange)
      handleChange(state.fields.map((item) => item.value).join("[/]"));
  }, [state.fields]);

  return (
    <Wrp flexDirection="column" maxWidth={450}>
      <>
        {title && (
          <Title
            level={6}
            title={title}
            fontWeight="500"
            color="#A6AEC5"
            marginBottom={5}
          />
        )}
        <ul
          onDragOver={(e) => e.preventDefault()}
          className={styles.listFieldDrag}
        >
          {fields}
        </ul>
        {pages && pages.length > 1 && paginated && (
          <Pagination
            page={page}
            pages={pages}
            paginationKey={paginationKey}
            handleLeftButtonPagination={handleLeftButtonPagination}
            handleRightButtonPagination={handleRightButtonPagination}
          />
        )}
        <input type="hidden" value={state.fields.join("[/]")} />
      </>
    </Wrp>
  );
};

export default DraggableField;

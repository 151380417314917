import { useFormik } from "formik";
import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import withMatch from "../../../../components/other/hoc/withMatch";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { createAdditionalFetch, setNewItemId } from "../../../../store/state/services/upsells/upsells.slice";
import { SUBMIT_ACTIONS } from "../../../../common/constants/submitActions";
import useRedirectToNewItemPage from "../../../../common/hooks/useRedirectToNewItemPage";
import useSubmitAction from "../../../../common/hooks/useSubmitAction";
import { UpsellValue } from "../models/UpsellValue";
import Upsell from "./Upsell";
import validationSchema from "./Upsell.scheme";

const NewUpsellContainer: FC = () => {
    const dispatch = useAppDispatch();
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const newItemId = useAppSelector(state => state.upsellsServices.newItemId, shallowEqual);

    const payload = {
        newItemId,
        setNewItemId: (id: number | null) => {
            dispatch(setNewItemId({id}));
        }
    }

    useRedirectToNewItemPage(payload);

    const initialValues: UpsellValue = {
        name: "",
        confirmed: true,
        price: 0,
        description: "",
        active: true,
        submitAction: SUBMIT_ACTIONS.SAVE,
    }

    const submitActionHandler = useSubmitAction();

    const formik = useFormik<UpsellValue>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const {submitAction, name, confirmed, price, description, active} = values;
            
            submitActionHandler(
                submitAction,
                () => createAdditionalFetch(
                    {   
                        vendorId, 
                        upsell: {
                            name,
                            confirmed,
                            price,
                            description,
                            active,
                        }
                    }
                )
            );
        },
    });

    return (
        <Upsell 
            formik={formik} 
            isNewItemForm={true} 
        />
    );
}

export default React.memo(withMatch(NewUpsellContainer, [2, 3, 4]));
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../common/constants/defaultValue";

interface Props {
    lable: string;
    nameField: string;
    purseId: number;
    error: string | undefined;
    touched: boolean | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void
}

const PursesDropdown: FC<Props> = ({ nameField, purseId, error, touched, setFieldValue, setFieldTouched, lable }) => {
    const purses = useAppSelector(state => state.money.purses, shallowEqual);

    const indexPurses = purses.findIndex(item => item.id === purseId);
    
    const defaultPurses = purses[indexPurses];
    const defaultValuePurses: DefaultValue = {
        valueInput: defaultPurses && String(defaultPurses.id) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: defaultPurses && defaultPurses.name || DEFAULT_VALUES_DROPDOWN.valueText,
    }

    const pursesItems = purses.map(purse => (
        <DropdownItem
            item={purse.name}
            valueInput={String(purse.id)}
            valueText={purse.name}
        />
    ));

    const changeHandler = (purseId: string) => {
        setFieldValue(nameField, parseInt(purseId));
    }

    const blurHandler = (touched: boolean) => {
        setFieldTouched(nameField, touched)
    }

    return (
        <Dropdown
            maxWidth={210}
            label={lable}
            defaultValue={defaultValuePurses}
            doubleClickDropdown={false}
            handleBlur={blurHandler}
            handleChange={changeHandler}
            error={error}
            touched={touched}
        >
            {pursesItems}
        </Dropdown>
    )
}

export default PursesDropdown;
import { useFormik } from "formik";
import moment from "moment";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { setFonVisiable } from "../../../../store/state/all/all.slice";
import { 
    createMoneyTransactionFetch, 
    searchCounteragentsAsClientsFetch, 
    setDefaultValueFinance, 
    setVisiableAddForm
} from "../../../../store/state/finance/finance.slice";
import { Transaction } from "../../../../store/state/finance/models/Transaction";
import { setDefaultValueGroups } from "../../../../store/state/groups/groups.slice";
import { Filter } from "../../../../common/models/Filters";
import AddFormValue from "../../models/AddFormValue";
import AddForm from "./AddForm";
import validationSchema from "./AddForm.scheme";

const AddFormContainer: FC = () => {
    const dispatch = useAppDispatch();

    const visiable = useAppSelector(state => state.finance.visiableAddForm, shallowEqual)
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const userId = useAppSelector(state => state.user.id, shallowEqual) || -1;
    const fullname = useAppSelector(state => state.user.name, shallowEqual);
    const roleId = useAppSelector(state => state.user.roleId, shallowEqual) || -1;
    
    const counteragentsAsClients = useAppSelector(state => state.finance.counteragentsAsClients, shallowEqual);

    const initialValues: AddFormValue = {
        counteragent: {
            id: null,
            userId: null,
            clientId: null,
            name: "",
        },
        responsibleUser: {
            id: userId,
            fullname: fullname,
            roleId: roleId,
        },
        studentId: -1,
        sum: 0,
        date: "",
        operationId: -1,
        purseId: -1,
        groupId: -1,
        comment: "",
    }

    const formik = useFormik<AddFormValue>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const { counteragent, groupId, date, responsibleUser, sum, purseId, operationId, comment, studentId } = values;

            const today = new Date();
            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const filter: Filter = {
                period: {
                    from: moment(firstDay).format("YYYY-MM-DD"),
                    to: moment(lastDay).format("YYYY-MM-DD"),
                }
            }

            const clientId = counteragent.clientId || -1;

            const transaction: Transaction = {
                counteragent: { userId: counteragent.userId, name: counteragent.name },
                groupId,
                date,
                responsibleUser,
                sum,
                purseId,
                operationId,
                studentId,
                income: true,
                comment,
            };

            dispatch(createMoneyTransactionFetch({ vendorId, clientId, transaction, filter }));
            closeModalHandler();
        },
    });

    const closeModalHandler = () => {
        formik.resetForm();
        dispatch(setVisiableAddForm({ visiable: false }));
        dispatch(setFonVisiable({ visiable: false }));
        dispatch(setDefaultValueFinance({ counteragents: true }));
        dispatch(setDefaultValueGroups({ searchGroups: true }));
    }

    const handleSearchCounteragents = (value: string) => {
        dispatch(searchCounteragentsAsClientsFetch({ vendorId, value }));
    }

    return (
        <AddForm
            formik={formik}
            visiable={visiable}
            closeModalHandler={closeModalHandler}
            handleSearchCounteragents={handleSearchCounteragents}
            counteragentsAsClients={counteragentsAsClients}
        />
    );
}

export default AddFormContainer;